import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import API from "API";
import styled from "styled-components";
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

class CurrentModal extends Component {
  state = {
    jobSearchStages: [],
    jobPositions: [],
    jobLevels: [],
    companyTypes: [],
    degrees: [],
    industries: [],
    jobSearchStage: {},
    currentJobPosition: {},
    currentJobLevel: {},
    currentAnnualSalaryMax: 0,
    currentAnnualSalaryMin: 0,
    currentCompanyType: {},
    currentDegree: {},
    currentIndustry: {},
  };

  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter={
      "include": ["currentIndustry", "currentDegree", "currentCompanyType", "currentJobLevel", "currentJobPosition", "jobSearchStage" ]
    }`
    ).then((response) => this.setState(response.data));

    API.get(`/JobSearchStages`).then((response) => {
      this.setState({
        jobSearchStages: response.data,
      });
    });

    API.get("/JobPositions").then((response) => {
      this.setState({
        jobPositions: response.data,
      });
    });

    API.get("/JobLevels").then((response) => {
      this.setState({
        jobLevels: response.data,
      });
    });

    API.get("/CompanyTypes").then((response) => {
      this.setState({
        companyTypes: response.data,
      });
    });

    API.get("/Degrees").then((response) => {
      this.setState({
        degrees: response.data,
      });
    });

    API.get("/Industries").then((response) => {
      this.setState({
        industries: response.data,
      });
    });
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    try {
      value = JSON.parse(value);
      this.setState({
        [name]: value,
      });
    } catch (e) {
      this.setState({
        [name]: value,
      });
    }
  };

  onSliderChange = (name, value) => {
    let min = value[0];
    let max = value[1];
    this.setState({
      currentAnnualSalaryMin: min,
      currentAnnualSalaryMax: max,
    });
  };

  handleSubmit = () => {
    API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
      currentAnnualSalaryMax: this.state.currentAnnualSalaryMax,
      currentAnnualSalaryMin: this.state.currentAnnualSalaryMin,
      currentJobPositionId: this.state.currentJob.id,
      currentJobLevelId: this.state.currentJobLevel.id,
      currentCompanyTypeId: this.state.currentCompanyType.id,
      currentDegreeId: this.state.currentDegree.id,
      currentIndustryId: this.state.currentIndustry.id,
      jobSearchStageId: this.state.jobSearchStage.id,
    }).then((response) => {
      this.props.getFreshData();
      this.props.closeModal();
    });
  };

  render() {
    return (
      <Modal
        show={this.props.displayModal === "current"}
        size="lg"
        animation={false}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <h1>What I'm doing right now</h1>
                <p className="text-muted">
                  What are you currently doing? What unique experiences and
                  qualifications do you currently have? Please describe your
                  current status here.
                </p>
              </Col>
            </Row>
            <Form>
              <Form.Row>
                <Col>
                  <Form.Label>
                    Where are you currently in your job search?
                  </Form.Label>
                  <Form.Control
                    name="jobSearchStage"
                    onChange={this.handleChange}
                    as="select"
                    value={JSON.stringify(this.state.jobSearchStage)}
                  >
                    <option disabled value={JSON.stringify({})}>
                      -- select an option --
                    </option>
                    {this.state.jobSearchStages.map((jobSearchStage) => (
                      <option value={JSON.stringify(jobSearchStage)}>
                        {jobSearchStage.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4">
                <Col>
                  <Form.Label>What is your current job?</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={this.handleChange}
                    name="currentJob"
                    value={JSON.stringify(this.state.currentJob)}
                  >
                    <option value={JSON.stringify({})}>
                      -- select an option --
                    </option>
                    {this.state.jobPositions.map((jobPosition) => (
                      <option value={JSON.stringify(jobPosition)}>
                        {jobPosition.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4">
                <Col>
                  <Form.Label>Current (or most recent) Job Level</Form.Label>
                  <Form.Control
                    as="select"
                    name="currentJobLevel"
                    value={JSON.stringify(this.state.currentJobLevel)}
                    onChange={this.handleChange}
                  >
                    <option disabled value={JSON.stringify({})}>
                      -- select an option --
                    </option>
                    {this.state.jobLevels.map((jobLevel) => (
                      <option value={JSON.stringify(jobLevel)}>
                        {jobLevel.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4">
                <Col>
                  <Form.Label>
                    What type of company are you working at?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={this.handleChange}
                    value={JSON.stringify(this.state.currentCompanyType)}
                    name="currentCompanyType"
                  >
                    <option disabled value={JSON.stringify({})}>
                      -- select an option --
                    </option>
                    {this.state.companyTypes.map((companyType) => (
                      <option value={JSON.stringify(companyType)}>
                        {companyType.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4">
                <Col>
                  <Form.Label>What education do you have?</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={this.handleChange}
                    value={JSON.stringify(this.state.currentDegree)}
                    name="currentDegree"
                  >
                    <option disabled value={JSON.stringify({})}>
                      -- select an option--
                    </option>
                    {this.state.degrees.map((degree) => (
                      <option value={JSON.stringify(degree)}>
                        {degree.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4">
                <Col>
                  <Form.Label>
                    Current (or most recent) industry you worked in
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="currentIndustry"
                    onChange={this.handleChange}
                    value={JSON.stringify(this.state.currentIndustry)}
                  >
                    <option disabled value={JSON.stringify({})}>
                      Enter your current (or most recent) industry
                    </option>
                    {this.state.industries.map((industry) => (
                      <option value={JSON.stringify(industry)}>
                        {industry.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4">
                <Col>
                  <Form.Label>
                    How much salary are you currently making?
                  </Form.Label>
                  <Range
                    min={0}
                    max={200000}
                    onChange={(d) =>
                      this.onSliderChange("currentAnnualSalary", d)
                    }
                    marks={{
                      0: "$0",
                      25000: "$25,000",
                      50000: "$50,000",
                      75000: "$75,000",
                      100000: "$100,000",
                      125000: "$125,000",
                      150000: "$150,000",
                      175000: "$175,000",
                      200000: "$200,000+",
                    }}
                    defaultValue={[
                      this.state.currentAnnualSalaryMin,
                      this.state.currentAnnualSalaryMax,
                    ]}
                    tipFormatter={(value) => `${value}%`}
                    railStyle={{
                      backgroundColor: "#f5f5f5",
                    }}
                    trackStyle={[
                      { backgroundColor: "#ED1C23" },
                      { backgroundColor: "#ED1C23" },
                    ]}
                    handleStyle={[
                      {
                        backgroundColor: "#ED1C23",
                        borderColor: "#ED1C23",
                      },
                      {
                        backgroundColor: "#ED1C23",
                        borderColor: "#ED1C23",
                      },
                    ]}
                    dotStyle={{
                      backgroundColor: "#f5f5f5",
                    }}
                    activeDotStyle={{
                      backgroundColor: "#ED1C23",
                      borderColor: "#ED1C23",
                    }}
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mt-5">
                <Col>
                  <Button
                    variant="outline-primary"
                    className="mr-1"
                    onClick={this.props.closeModal}
                  >
                    Exit
                  </Button>
                  <Button className="float-right" onClick={this.handleSubmit}>
                    Update
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}

export default CurrentModal;
