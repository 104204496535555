import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import API from "API";
import styled from "styled-components";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Select from "react-select";
import { GeoSelectState, GeoSelectCity } from "GeoSelect";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const officeTypes = ["Remote Work", "Office Work", "Office-first hybrid", "Remote-first hybrid"]

class DesiresModal extends Component {
  state = {
    jobPositions: [],
    jobPositionSpecialties: [],
    jobLevels: [],
    annualSalaryMin: 0,
    annualSalaryMax: 0,
    companyTypes: [],
    industries: [],
    desiredAnnualSalaryMin: 0,
    desiredAnnualSalaryMax: 0,
    desiredJobPosition: {},
    jobPositionJobPositionSpecialties: [],
    selectedJobPositionSpecialties: [],
    userDesiredJobLevel: {},
    userPitch: "",
    userDesiredIndustry: {},
    userDesiredCompanyType: {},
    userDesiredJobLevel: {},
    desiredSearchDistanceMin: 0,
    desiredSearchDistanceMax: 0,
    desiredCities: [],
    desiredStates: [],
    userDesiredIndustries: [],
    userDesiredCompanyTypes: [],
    employmentType: {},
    employmentTypes: [],
    desiredOfficeTypes: [],
  };

  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter={
        "include": ["avatar", "userDesiredIndustry", "userDesiredCompanyType", "userDesiredJobLevel", "userDesiredCompanyTypes", "userDesiredIndustries", "employmentType" ]
      }`
    ).then((response) =>
      this.setState({
        ...response.data,
        userDesiredIndustries: response.data.userDesiredIndustries.map((i) => ({
          label: i.name,
          value: i,
        })),
        userDesiredCompanyTypes: response.data.userDesiredCompanyTypes.map(
          (c) => ({
            label: c.name,
            value: c,
          })
        ),
      })
    );

    API.get(`/EmploymentTypes`).then((response) => {
      this.setState({
        employmentTypes: response.data,
      });
    });

    API.get(`/JobPositions?filter={"order": "name ASC" }`).then((response) => {
      this.setState({
        jobPositions: response.data.sort(),
      });
    });

    API.get(`/JobPositionSpecialties?filter={"order": "name ASC" }`).then(
      (response) => {
        this.setState({
          jobPositionSpecialties: response.data.sort(),
        });
      }
    );

    API.get(`/CompanyTypes?filter={"order": "priority ASC"}`).then(
      (response) => {
        this.setState({
          companyTypes: response.data.sort(),
        });
      }
    );

    API.get(`/Industries?filter={"order": "name ASC" }`).then((response) => {
      let data = response.data;
      data.some(
        (item, idx) =>
          item.name == "I'm interested in any industry" &&
          data.unshift(data.splice(idx, 1)[0])
      );
      this.setState({
        industries: data,
      });
    });

    API.get(`/JobLevels?filter={"order": "priority ASC"}`).then((response) => {
      this.setState({
        jobLevels: response.data,
      });
    });

    API.get(
      `/UserProfiles/${this.props.userToken.userId}/userDesiredJobPositionSpecialties?filter[include]=jobPositionSpecialty`
    ).then((response) => {
      // let selectedJobPositionSpecialties = response.data.map(
      //   jobPositionSpecialty => {
      //     jobPositionSpecialty.name =
      //       jobPositionSpecialty.jobPositionSpecialty.name;
      //     return jobPositionSpecialty;
      //   }
      // );
      // this.setState({
      //   selectedJobPositionSpecialties
      // });
      this.setState({
        selectedJobPositionSpecialties: response.data,
      });
    });
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    try {
      value = JSON.parse(value);
      this.setState({
        [name]: value,
      });
    } catch (e) {
      this.setState({
        [name]: value,
      });
    }
  };

  screamChangeSingleDesires = (v, attr) => {
    this.setState({
      [attr]: v.value,
    });
    let jobPosition = v.value;
    let jobPositionSpecialties = this.state.jobPositionSpecialties;
    let filteredJobPositionSpecialties = jobPositionSpecialties.filter(
      (jobPositionSpecialty) => {
        return jobPosition.id === jobPositionSpecialty.jobPositionId;
      }
    );
    this.setState({
      jobPositionJobPositionSpecialties: filteredJobPositionSpecialties,
      desiredJobPosition: jobPosition,
    });
  };

  handleDesiredJobPosition = (e) => {
    let { name, value } = e.target;
    let jobPosition = JSON.parse(value);
    let jobPositionSpecialties = this.state.jobPositionSpecialties;
    let filteredJobPositionSpecialties = jobPositionSpecialties.filter(
      (jobPositionSpecialty) => {
        return jobPosition.id === jobPositionSpecialty.jobPositionId;
      }
    );
    this.setState({
      jobPositionJobPositionSpecialties: filteredJobPositionSpecialties,
      desiredJobPosition: jobPosition,
    });
  };

  handleMultiSelect = (v, attr) => {
    if (v === null) {
      this.setState({
        [attr]: [],
      });
    } else {
      this.setState({
        [attr]: v,
      });
    }
  };

  handleAddJobPositionSpecialty = (e) => {
    let { name, value } = e.target;
    let jobPositionSpecialty = JSON.parse(value);
    jobPositionSpecialty.experience = 0;
    this.setState({
      selectedJobPositionSpecialties:
        this.state.selectedJobPositionSpecialties.concat(jobPositionSpecialty),
    });
  };

  screamChange = (v) => {
    if (
      this.state.selectedJobPositionSpecialties.find(
        (jobPositionSpecialty) => jobPositionSpecialty.id === v[0].value.id
      ) === undefined
    ) {
      if (v.length <= 5) {
        let selectedSpecialties = v.map((selectedSpecialty) => {
          return {
            ...selectedSpecialty.value,
            jobPositionSpecialty: {
              ...selectedSpecialty.value,
            },
            experience:
              "experience" in selectedSpecialty.value
                ? selectedSpecialty.value.experience
                : 0,
          };
        });

        console.log(selectedSpecialties);

        this.setState({
          selectedJobPositionSpecialties:
            this.state.selectedJobPositionSpecialties.concat(
              selectedSpecialties
            ),
          desiredJobPosition: {},
        });
      }
    }
  };

  handleRemoveSelectedJobPositionSpecialty = (jobPositionSpecialtyId) => {
    this.setState({
      selectedJobPositionSpecialties:
        this.state.selectedJobPositionSpecialties.filter(
          (jobPositionSpecialty) =>
            jobPositionSpecialty.id !== jobPositionSpecialtyId
        ),
    });
  };

  onSliderChange = (name, value) => {
    if (name == "desiredAnnualSalary") {
      this.setState({
        desiredAnnualSalaryMin: value,
      });
    }

    if (name == "desiredSearchDistance") {
      this.setState({
        desiredSearchDistanceMin: value,
      });
    }
  };

  onExperienceSliderChange = (sliderId, sliderValue) => {
    let selectedJobPositionSpecialties =
      this.state.selectedJobPositionSpecialties;
    let updatedJobPositionSpecialties = selectedJobPositionSpecialties.map(
      (jobPositionSpecialty) => {
        if (sliderId === jobPositionSpecialty.id) {
          jobPositionSpecialty.experience = sliderValue;
        }
        return jobPositionSpecialty;
      }
    );
    this.setState({
      selectedJobPositionSpecialties: updatedJobPositionSpecialties,
    });
  };

  handleSubmit = async () => {
    await API.delete(
      `/UserProfiles/${this.props.userToken.userId}/userDesiredJobPositionSpecialties`
    );

    // this.state.selectedJobPositionSpecialties.map((jobPositionSpecialty) => {
    //   if ("jobPositionSpecialtyId" in jobPositionSpecialty) {
    //     API.put(
    //       `/UserProfiles/${this.props.userToken.userId}/userDesiredJobPositionSpecialties/${jobPositionSpecialty.id}`,
    //       {
    //         experience: jobPositionSpecialty.experience,
    //       }
    //     );
    //   } else {
    //     API.post(
    //       `/UserProfiles/${this.props.userToken.userId}/userDesiredJobPositionSpecialties`,
    //       {
    //         jobPositionSpecialtyId:
    //           jobPositionSpecialty.jobPositionSpecialty.id,
    //         experience: jobPositionSpecialty.experience,
    //       }
    //     );
    //   }
    // });

    this.state.selectedJobPositionSpecialties.map((jobPositionSpecialty) => {
      API.post(
        `/UserProfiles/${this.props.userToken.userId}/userDesiredJobPositionSpecialties`,
        {
          jobPositionSpecialtyId: jobPositionSpecialty.jobPositionSpecialty.id,
          experience: jobPositionSpecialty.experience,
        }
      );
    });

    API.delete(
      `/UserProfiles/${this.props.userToken.userId}/userDesiredIndustries`
    ).then(() => {
      this.state.userDesiredIndustries.map((industry) => {
        let ind = industry.value;
        API.put(
          `/UserProfiles/${this.props.userToken.userId}/userDesiredIndustries/rel/${ind.id}`,
          {
            name: ind.name,
            slug: ind.slug,
          }
        );
      });
    });

    API.delete(
      `/UserProfiles/${this.props.userToken.userId}/userDesiredCompanyTypes`
    ).then(() => {
      this.state.userDesiredCompanyTypes.map((companyType) => {
        let ct = companyType.value;
        API.put(
          `/UserProfiles/${this.props.userToken.userId}/userDesiredCompanyTypes/rel/${ct.id}`,
          {
            name: ct.name,
            slug: ct.slug,
          }
        );
      });
    });

    API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
      userDesiredIndustryId: this.state.userDesiredIndustry.id,
      userDesiredCompanyTypeId: this.state.userDesiredCompanyType.id,
      userDesiredJobLevelId: this.state.userDesiredJobLevel.id,
      desiredAnnualSalaryMin: this.state.desiredAnnualSalaryMin,
      desiredAnnualSalaryMax: this.state.desiredAnnualSalaryMax,
      desiredOfficeTypes: this.state.desiredOfficeTypes,
      desiredCities: this.state.desiredCities,
      desiredStates: this.state.desiredStates,
      desiredSearchDistanceMax: this.state.desiredSearchDistanceMax,
      desiredSearchDistanceMin: this.state.desiredSearchDistanceMin,
      employmentTypeId: this.state.employmentType.id,
      userPitch: this.state.userPitch,
    }).then((response) => {
      API.get(
        `/UserProfiles/${this.props.userToken.userId}?filter={
          "include": ["avatar", "userDesiredIndustry", "userDesiredCompanyType", "userDesiredJobLevel", "userDesiredCompanyTypes", "userDesiredIndustries", "employmentType" ]
        }`
      ).then((response) => {
        this.setState({
          userPitch: response.data.userPitch,
        });
      });
      this.props.getFreshData();
      this.props.onboard
        ? this.props.editModal("writingSample")
        : this.props.closeModal();
    });
  };

  handleSelectChange = (inputVals, opt) => {
    let { name } = opt;

    this.setState({
      [name]: inputVals === null ? [] : inputVals,
    });
  };

  render() {
    return (
      <Modal
        show={this.props.displayModal === "desires"}
        dialogClassName="modal-65w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Body>
          <ModalContainer>
            <img
              style={{
                position: "absolute",
                zIndex: 100,
                width: 175,
                borderRadius: 8,
                left: 30,
                top: 30,
              }}
              src={require("images/logo/tmcf-logo-black.png")}
            />
            <ModalColumn className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
              <img
                style={{
                  width: 450,
                  bottom: 0,
                }}
                src={require("images/modals/gogig-career-desires.png")}
              />
            </ModalColumn>
            <ModalColumn className="p-5 w-100 bg-white">
              <Row>
                <Col>
                  <h1 style={{ fontWeight: "normal" }}>Your Career Desires</h1>
                  <p>
                    Tell us where you want to be, so we can help you get there!
                  </p>
                </Col>
              </Row>
              <Form>
                <Form.Row>
                  {this.state.selectedJobPositionSpecialties.map(
                    (jobPositionSpecialty) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          backgroundColor: "rgb(196, 207, 213)",
                          fontWeight: "bold",
                          padding: 10,
                          borderRadius: 100,
                          margin: 5,
                        }}
                      >
                        <p
                          style={{
                            fontSize: 13,
                            margin: 0,
                          }}
                        >
                          {
                            jobPositionSpecialty.jobPositionSpecialty
                              .jobPosition.name
                          }
                          : {jobPositionSpecialty.jobPositionSpecialty.name}
                        </p>
                        <p
                          style={{
                            fontSize: 13,
                            margin: 0,
                            marginLeft: 10,
                            marginRight: 10,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            this.handleRemoveSelectedJobPositionSpecialty(
                              jobPositionSpecialty.id
                            )
                          }
                        >
                          X
                        </p>
                      </div>
                    )
                  )}
                </Form.Row>
                <Form.Row className="mt-2">
                  <Col>
                    <div>
                      <p
                        style={{
                          margin: 0,
                          color: "#000",
                          padding: 0,
                          fontWeight: 500,
                        }}
                      >
                        Desired position type for your next career move
                      </p>
                      {this.state.selectedJobPositionSpecialties.length >= 3 ? (
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            color: "#A5BBC2",
                            fontSize: 15,
                          }}
                        >
                          <i>
                            You can only choose up to three combinations of
                            position types and specialties. Please adjust your
                            choices above.
                          </i>
                        </p>
                      ) : (
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            color: "#999",
                            fontSize: 15,
                          }}
                        >
                          Choose up to three combinations of position types and
                          specialties
                        </p>
                      )}
                    </div>
                    {this.state.selectedJobPositionSpecialties.length < 3 && (
                      <Select
                        onChange={(v) =>
                          this.screamChangeSingleDesires(
                            v,
                            "desiredJobPosition"
                          )
                        }
                        styles={{
                          container: (base, state) => ({
                            ...base,
                            width: "100%",
                            outline: "none",
                          }),
                          control: (base, state) => ({
                            ...base,
                            border: "none",
                            borderBottom: "1px solid silver",
                            outline: "none",
                            padding: 0,
                            margin: 0,
                            borderRadius: 0,
                            outline: "none",
                          }),
                        }}
                        value={{
                          label: this.state.desiredJobPosition.name,
                          value: this.state.desiredJobPosition,
                        }}
                        defaultValue={{
                          label: this.state.desiredJobPosition.name,
                          value: this.state.desiredJobPosition,
                        }}
                        placeholder="Select Job Position"
                        isMulti={false}
                        options={this.state.jobPositions.map((jobPosition) => {
                          return {
                            label: jobPosition.name,
                            value: jobPosition,
                          };
                        })}
                      />
                    )}
                  </Col>
                </Form.Row>

                {this.state.selectedJobPositionSpecialties.length < 3 &&
                  Object.keys(this.state.desiredJobPosition).length !== 0 && (
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>
                          What specialty within{" "}
                          {this.state.desiredJobPosition.name} are you
                          interested in?
                        </Form.Label>
                        <Select
                          onChange={this.screamChange}
                          styles={{
                            container: (base, state) => ({
                              ...base,
                              width: "100%",
                              outline: "none",
                            }),
                            control: (base, state) => ({
                              ...base,
                              border: "none",
                              borderBottom: "1px solid silver",
                              outline: "none",
                              padding: 0,
                              margin: 0,
                              borderRadius: 0,
                              outline: "none",
                            }),
                          }}
                          isMulti={true}
                          value={[]}
                          // defaultValue={this.state.selectedJobPositionSpecialties.map(
                          //   selectedSpecialty => {
                          //     return {
                          //       label: selectedSpecialty.name,
                          //       value: selectedSpecialty
                          //     };
                          //   }
                          // )}
                          options={this.state.jobPositionJobPositionSpecialties.map(
                            (jobPositionSpecialty) => {
                              return {
                                label: jobPositionSpecialty.name,
                                value: jobPositionSpecialty,
                              };
                            }
                          )}
                        />
                      </Col>
                    </Form.Row>
                  )}

                {Object.keys(this.state.desiredJobPosition).length !== 0 && (
                  <Form.Row className="mt-4">
                    <Col>
                      <Form.Label>
                        Years of experience in these specialties:
                      </Form.Label>
                    </Col>
                  </Form.Row>
                )}
                {this.state.selectedJobPositionSpecialties.map(
                  (jobPositionSpecialty, index) => (
                    <Form.Row className={index == 0 ? "mt-2" : "mt-5"}>
                      <Col>
                        <Form.Label>
                          {
                            jobPositionSpecialty.jobPositionSpecialty
                              .jobPosition.name
                          }
                          : {jobPositionSpecialty.jobPositionSpecialty.name}
                        </Form.Label>
                        <SliderValueContainer>
                          <SliderValue>
                            {jobPositionSpecialty.experience} years
                          </SliderValue>
                        </SliderValueContainer>
                        <Slider
                          defaultValue={jobPositionSpecialty.experience}
                          min={0}
                          max={10}
                          onChange={(d) =>
                            this.onExperienceSliderChange(
                              jobPositionSpecialty.id,
                              d
                            )
                          }
                          marks={{
                            0: "0",
                            10: "10+ Years",
                          }}
                          allowCross={false}
                          tipFormatter={(value) => `${value}`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10,
                          }}
                          trackStyle={[
                            { backgroundColor: "#D3202A", height: 10 },
                            { backgroundColor: "#D3202A", height: 10 },
                          ]}
                          handleStyle={[
                            {
                              backgroundColor: "#D3202A",
                              borderColor: "#D3202A",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                            {
                              backgroundColor: "#D3202A",
                              borderColor: "#D3202A",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                          ]}
                          dotStyle={{
                            display: "none",
                          }}
                        />
                      </Col>
                    </Form.Row>
                  )
                )}

                <Form.Row className="mt-5">
                  <Col>
                    <Form.Label>
                      Desired job level for your next career move
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="userDesiredJobLevel"
                      onChange={this.handleChange}
                      value={JSON.stringify(this.state.userDesiredJobLevel)}
                    >
                      <option disabled value={JSON.stringify({})}>
                        Select A Job Level
                      </option>{" "}
                      {this.state.jobLevels.map((jobLevel) => (
                        <option value={JSON.stringify(jobLevel)}>
                          {jobLevel.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Row>

                <Form.Row className="mt-5">
                  <Col>
                    <Form.Label>
                      How much total annual compensation are you targeting from
                      your next career?{" "}
                    </Form.Label>
                    <SliderValueContainer
                      style={{
                        position: "relative",
                        bottom: 25,
                      }}
                    >
                      <SliderValue>
                        {this.state.desiredAnnualSalaryMin === 500000
                          ? "$" +
                            this.state.desiredAnnualSalaryMin.toLocaleString() +
                            "+"
                          : "$" +
                            this.state.desiredAnnualSalaryMin.toLocaleString()}
                      </SliderValue>
                    </SliderValueContainer>
                    <Slider
                      min={0}
                      max={500000}
                      step={5000}
                      onChange={(d) =>
                        this.onSliderChange("desiredAnnualSalary", d)
                      }
                      marks={{
                        0: "0",
                        500000: "$500,000+",
                      }}
                      defaultValue={this.state.desiredAnnualSalaryMin}
                      allowCross={false}
                      tipFormatter={(value) => `${value}`}
                      railStyle={{
                        backgroundColor: "#f5f5f5",
                        height: 10,
                      }}
                      trackStyle={[
                        { backgroundColor: "#D3202A", height: 10 },
                        { backgroundColor: "#D3202A", height: 10 },
                      ]}
                      handleStyle={[
                        {
                          backgroundColor: "#D3202A",
                          borderColor: "#D3202A",
                          width: 17,
                          height: 17,
                          top: 6,
                        },
                        {
                          backgroundColor: "#D3202A",
                          borderColor: "#D3202A",
                          width: 17,
                          height: 17,
                          top: 6,
                        },
                      ]}
                      dotStyle={{
                        display: "none",
                      }}
                    />
                  </Col>
                </Form.Row>

                <Form.Row className="mt-5">
                  <Col>
                    <Form.Label>
                      What industry do you want to be working in?
                    </Form.Label>
                    <Select
                      defaultValue={this.state.userDesiredIndustries}
                      onChange={(v) =>
                        this.handleMultiSelect(v, "userDesiredIndustries")
                      }
                      styles={{
                        container: (base, state) => ({
                          ...base,
                          width: "100%",
                          outline: "none",
                        }),
                        multiValueLabel: (base, state) => ({
                          ...base,
                          color: "#000",
                        }),
                        valueContainer: (base, state) => ({
                          ...base,
                          padding: 0,
                        }),
                        multiValue: (base, state) => ({
                          ...base,
                          borderRadius: 30,
                          backgroundColor: "rgb(196, 207, 213)",
                          fontWeight: "bold",
                          marginRight: 10,
                          marginBottom: 10,
                        }),
                        control: (base, state) => ({
                          ...base,
                          border: "none",
                          borderBottom: "1px solid silver",
                          outline: "none",
                          padding: 0,
                          margin: 0,
                          borderRadius: 0,
                          outline: "none",
                        }),
                      }}
                      placeholder="Enter up to 3 desired industries"
                      isMulti={true}
                      options={
                        this.state.userDesiredIndustries.length >= 3
                          ? []
                          : this.state.industries.map((industry) => {
                              return {
                                label: industry.name,
                                value: industry,
                              };
                            })
                      }
                    />
                    {this.state.userDesiredIndustries.length >= 3 && (
                      <p
                        style={{
                          marginTop: 40,
                          color: "#A5BBC2",
                        }}
                      >
                        <i>
                          You can only choose up to 3 desired industries. Please
                          remove one to adjust your selection.
                        </i>
                      </p>
                    )}
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col>
                    <Form.Label className="mt-5">
                      Desired company type for your next career move
                    </Form.Label>
                    <Select
                      onChange={(v) =>
                        this.handleMultiSelect(v, "userDesiredCompanyTypes")
                      }
                      defaultValue={this.state.userDesiredCompanyTypes}
                      styles={{
                        container: (base, state) => ({
                          ...base,
                          width: "100%",
                          outline: "none",
                        }),
                        control: (base, state) => ({
                          ...base,
                          border: "none",
                          borderBottom: "1px solid silver",
                          outline: "none",
                          padding: 0,
                          margin: 0,
                          borderRadius: 0,
                          outline: "none",
                        }),
                        valueContainer: (base, state) => ({
                          ...base,
                          padding: 0,
                        }),
                        multiValue: (base, state) => ({
                          ...base,
                          borderRadius: 30,
                          backgroundColor: "rgb(196, 207, 213)",
                          fontWeight: "bold",
                          marginRight: 10,
                          marginBottom: 10,
                        }),
                      }}
                      placeholder="Desired Company Type(s)"
                      isMulti={true}
                      options={this.state.companyTypes.map((ct) => {
                        return {
                          label: ct.name,
                          value: ct,
                        };
                      })}
                    />
                  </Col>
                </Form.Row>

                {/* <Form.Row className="mt-2">
                  <Col>
                    <Form.Label>
                      What type of company do you want to work at?
                    </Form.Label>
                    <Form.Control
                      name="userDesiredCompanyType"
                      as="select"
                      onChange={this.handleChange}
                      value={JSON.stringify(this.state.userDesiredCompanyType)}
                    >
                      <option disabled value={JSON.stringify({})}>
                        -- select an option --
                      </option>
                      {this.state.companyTypes.map(companyType => (
                        <option value={JSON.stringify(companyType)}>
                          {companyType.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Row> */}

                {/* <Form.Row className="mt-2">
                  <Col>
                    <Form.Label>
                      Desired position type for your next move
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="desiredJobPosition"
                      onChange={this.handleDesiredJobPosition}
                    >
                      <option selected value disabled>
                        -- select an option --
                      </option>
                      {this.state.jobPositions.map(jobPosition => (
                        <option value={JSON.stringify(jobPosition)}>
                          {jobPosition.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Row> */}

                {/* <Form.Row className="mt-2">
                  <Col>
                    <Form.Label>
                      What specialties within "
                      {this.state.desiredJobPosition.name}" are you interested
                      in?
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="jobPositionSpecialty"
                      multiple
                    >
                      {this.state.jobPositionJobPositionSpecialties.map(
                        jobPositionSpecialty => (
                          <option
                            onDoubleClick={this.handleAddJobPositionSpecialty}
                            value={JSON.stringify(jobPositionSpecialty)}
                          >
                            {jobPositionSpecialty.name}
                          </option>
                        )
                      )}
                    </Form.Control>
                  </Col>
                </Form.Row>
                {this.state.selectedJobPositionSpecialties.map(
                  jobPositionSpecialty => (
                    <Form.Row className="mt-5">
                      <Col>
                        <Form.Row>
                          <Form.Label>{jobPositionSpecialty.name}</Form.Label>
                          <SliderValueContainer>
                            <SliderValue>
                              {jobPositionSpecialty.experience} years
                            </SliderValue>
                          </SliderValueContainer>
                        </Form.Row>
                        <Slider
                          defaultValue={jobPositionSpecialty.experience}
                          min={0}
                          max={10}
                          onChange={d =>
                            this.onExperienceSliderChange(
                              jobPositionSpecialty.id,
                              d
                            )
                          }
                          marks={{
                            0: "0",
                            10: "10+ Years"
                          }}
                          tipFormatter={value => `${value}%`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10
                          }}
                          trackStyle={[
                            { backgroundColor: "#D3202A", height: 10 },
                            { backgroundColor: "#D3202A", height: 10 }
                          ]}
                          handleStyle={[
                            {
                              backgroundColor: "#D3202A",
                              borderColor: "#D3202A",
                              width: 17,
                              height: 17,
                              top: 6
                            },
                            {
                              backgroundColor: "#D3202A",
                              borderColor: "#D3202A",
                              width: 17,
                              height: 17,
                              top: 6
                            }
                          ]}
                          dotStyle={{
                            display: "none"
                          }}
                        />
                      </Col>
                    </Form.Row>
                  )
                )} */}

                {/* <Form.Row className="mt-5">
                <Col>
                  <Form.Label>What states do you want to work in?</Form.Label>
                  <GeoSelectState
                    name="desiredStates"
                    isMulti={true}
                    handleChange={this.handleSelectChange}
                    value={this.state.desiredStates}
                  />
                </Col>
              </Form.Row> */}

                <Form.Row className="mt-5">
                  <Col>
                    <Form.Label>
                      Where are your desired location(s)?{" "}
                    </Form.Label>
                    <GeoSelectCity
                      name="desiredCities"
                      isMulti={true}
                      handleChange={this.handleSelectChange}
                      value={this.state.desiredCities}
                      options={this.state.desiredCities.length >= 4 ? [] : null}
                      isDisabled={true}
                    />
                    {this.state.desiredCities.length >= 4 && (
                      <p
                        style={{
                          marginTop: 40,
                          color: "#A5BBC2",
                        }}
                      >
                        <i>
                          You can only choose up to 4 desired locations. If you
                          desire to be anywhere in the United States, drag the
                          radius slider bar all the way to the right
                        </i>
                      </p>
                    )}
                  </Col>
                </Form.Row>

                <Form.Row className="mt-4">
                  <Col>
                    <Form.Label>Desired Work Type</Form.Label>
                    <Form.Control
                      as="select"
                      name="employmentType"
                      onChange={this.handleChange}
                      value={JSON.stringify(this.state.employmentType)}
                    >
                      <option disabled value={JSON.stringify({})}>
                        Select Desired Work Type
                      </option>
                      {this.state.employmentTypes.map((employmentType) => (
                        <option value={JSON.stringify(employmentType)}>
                          {employmentType.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Row>

                <Form.Row className="mt-5">
                  <Col>
                    <Form.Row>
                      <Form.Label>Search Distance</Form.Label>
                      <p className="m-0 p-0 text-muted">
                        We'll network you directly with hiring companies within
                        the below miles from your selected location(s).
                      </p>
                      <SliderValueContainer>
                        <SliderValue>
                          {this.state.desiredSearchDistanceMin === 400
                            ? "Nationwide"
                            : `${this.state.desiredSearchDistanceMin} miles`}
                        </SliderValue>
                      </SliderValueContainer>
                    </Form.Row>
                    <Slider
                      min={0}
                      max={400}
                      defaultValue={this.state.desiredSearchDistanceMin}
                      onChange={(d) =>
                        this.onSliderChange("desiredSearchDistance", d)
                      }
                      marks={{
                        0: "0",
                        400: "Nationwide",
                      }}
                      tipFormatter={(value) => `${value}%`}
                      railStyle={{
                        backgroundColor: "#f5f5f5",
                        height: 10,
                      }}
                      trackStyle={[
                        { backgroundColor: "#D3202A", height: 10 },
                        { backgroundColor: "#D3202A", height: 10 },
                      ]}
                      handleStyle={[
                        {
                          backgroundColor: "#D3202A",
                          borderColor: "#D3202A",
                          width: 17,
                          height: 17,
                          top: 6,
                        },
                        {
                          backgroundColor: "#D3202A",
                          borderColor: "#D3202A",
                          width: 17,
                          height: 17,
                          top: 6,
                        },
                      ]}
                      dotStyle={{
                        display: "none",
                      }}
                    />
                  </Col>
                </Form.Row>

                {/* <Form.Row className="mt-5">
                  <Col>
                    <Form.Row>
                      <Form.Label>
                        How much salary are you targeting for your next career?
                      </Form.Label>
                      <SliderValueContainer>
                        <SliderValue>
                          ${this.state.desiredAnnualSalaryMin.toLocaleString()}
                        </SliderValue>
                      </SliderValueContainer>
                    </Form.Row>
                    <Slider
                      min={0}
                      max={200000}
                      onChange={d =>
                        this.onSliderChange("desiredAnnualSalary", d)
                      }
                      defaultValue={this.state.desiredAnnualSalaryMin}
                      marks={{
                        0: "$0",
                        200000: "$200,000+"
                      }}
                      tipFormatter={value => `${value}%`}
                      railStyle={{
                        backgroundColor: "#f5f5f5",
                        height: 10
                      }}
                      trackStyle={[
                        { backgroundColor: "#D3202A", height: 10 },
                        { backgroundColor: "#D3202A", height: 10 }
                      ]}
                      handleStyle={[
                        {
                          backgroundColor: "#D3202A",
                          borderColor: "#D3202A",
                          width: 17,
                          height: 17,
                          top: 6
                        },
                        {
                          backgroundColor: "#D3202A",
                          borderColor: "#D3202A",
                          width: 17,
                          height: 17,
                          top: 6
                        }
                      ]}
                      dotStyle={{
                        display: "none"
                      }}
                    />
                  </Col>
                </Form.Row> */}
                <Form.Row>
                  <Col>
                    <Form.Label className="mt-5">
                      Desired Office Type(s)
                    </Form.Label>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#999",
                        fontSize: 15,
                      }}
                    >
                      Please select the office type(s) you desire. You can choose up to four options.
                    </p>
                    <Select
                      onChange={(v) =>
                        this.handleMultiSelect(v, "desiredOfficeTypes")
                      }
                      defaultValue={this.state.desiredOfficeTypes}
                      styles={{
                        container: (base, state) => ({
                          ...base,
                          width: "100%",
                          outline: "none",
                        }),
                        control: (base, state) => ({
                          ...base,
                          border: "none",
                          borderBottom: "1px solid silver",
                          outline: "none",
                          padding: 0,
                          margin: 0,
                          borderRadius: 0,
                          outline: "none",
                        }),
                        valueContainer: (base, state) => ({
                          ...base,
                          padding: 0,
                        }),
                        multiValue: (base, state) => ({
                          ...base,
                          borderRadius: 30,
                          backgroundColor: "rgb(196, 207, 213)",
                          fontWeight: "bold",
                          marginRight: 10,
                          marginBottom: 10,
                        }),
                      }}
                      placeholder="Select Desired Office Type(s)"
                      isMulti={true}
                      options={officeTypes.map((ct, index) => {
                        return {
                          label: ct,
                          value: index,
                        };
                      })}
                    />
                  </Col>
                </Form.Row>

                <Form.Row className="mt-4">
                  <Col>
                    <Form.Label>TMCF Pitch</Form.Label>
                    <Form.Control
                      className="p-3"
                      style={{
                        padding: 20,
                        backgroundColor: "#F5F9FB",
                      }}
                      onChange={this.handleChange}
                      value={this.state.userPitch}
                      as="textarea"
                      maxlength={500}
                      rows="15"
                      name="userPitch"
                      placeholder="A summary of your achievements and previous success"
                    />
                  </Col>
                </Form.Row>
                <div>
                  <p>
                    Characters Remaining: {500 - this.state.userPitch.length}
                  </p>
                </div>
                <Form.Row className="mt-4">
                  <Col>
                    <Button
                      variant="outline-primary"
                      className="mr-1"
                      onClick={this.props.closeModal}
                    >
                      Exit
                    </Button>
                    <Button className="float-right" onClick={this.handleSubmit}>
                      {this.props.onboard ? "Next" : "Update"}
                    </Button>
                  </Col>
                </Form.Row>
              </Form>
            </ModalColumn>
          </ModalContainer>
        </Modal.Body>
      </Modal>
    );
  }
}

export default DesiresModal;

const JobPositionSpecialtyBubble = styled.div`
  background: red;
  margin: 5px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;
const JobPositionSpecialtyTitle = styled.p`
  margin: 0;
  padding: 0;
`;
const JobPositionSpecialtyRemove = styled.p`
  margin: 0;
  padding: 0;
  margin-left: 5px;
`;

const SliderValueContainer = styled.div`
  margin: 0;
  padding: 0;
  margin-left: auto;
  font-weight: 500;
  color: #020000;
  display: inline-block;
  float: right;
`;
const SliderValue = styled.p`
  margin: 0;
  padding: 0;
`;

const BackgroundImg = styled.div`
  width: 300px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://farm4.staticflickr.com/3902/14985871946_24f47d4b53_h.jpg");
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  margin: -16px;
`;
const ModalColumn = styled.div`
  background: #edeff0;
`;
