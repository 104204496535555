import React, { Component } from "react";
import API from "API";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../images/logo/tmcf-logo-black.png";
import Lottie from "react-lottie";
import { BrowserView, MobileOnlyView } from "react-device-detect";
import detective from "Lotties/detective-gogig.json";
import { Avatar } from "Stories/Avatars.stories.js";

class LandingScreen extends Component {
  render() {
    return (
      <>
        <BrowserView>
          <Container
            fluid={true}
            style={{
              backgroundImage: `url(${require("images/new-landing-bg.png")})`,
              backgroundSize: "cover",
              opacity: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <div class="overlay"></div>
            <SplitContainer
              style={{
                backgroundImage: `url(${require("images/landing-blob.png")})`,
                backgroundSize: "cover",
                padding: 100,
                minWidth: "50%",
                zIndex: 100,
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <GoGigLogo src={logo} />
              <Title>Welcome to TMCF HBCU Alumni Career Connect</Title>
              <Subtitle>Start by telling us who you are...</Subtitle>
              <Row>
                <Col>
                  <Link to="/signup">
                    <ItemContainer>
                      <Image src={require("images/landing-man.png")} />
                      <ItemTitle style={{ margin: 0, marginTop: 43 }}>
                        Candidate
                      </ItemTitle>
                    </ItemContainer>
                  </Link>
                </Col>
                <Col>
                  <Link to="/signup?recruiter=true">
                    <ItemContainer
                      style={{
                        paddingLeft: 40,
                        paddingRight: 40,
                      }}
                    >
                      <Image src={require("images/landing-woman.png")} />
                      <ItemTitle style={{ margin: 0 }}>
                        Hiring Company
                      </ItemTitle>
                    </ItemContainer>
                  </Link>
                </Col>
              </Row>
              <FooterText>
                Already have an account?{" "}
                <Link to="/login">
                  <span style={{ fontWeight: "bold", color: "#ed1c23" }}>
                    Log In
                  </span>
                </Link>
                <p
                  style={{
                    fontSize: 12,
                    color: "red",
                    textAlign: "center",
                    position: "relative",
                    top: 10,
                  }}
                >
                  Powered By GoGig
                </p>
              </FooterText>
            </SplitContainer>
          </Container>
        </BrowserView>
        <MobileOnlyView>
          <Container
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100vh",
              backgroundImage: `url(${require("images/landing-blob.png")})`,
            }}
          >
            <GoGigLogo src={logo} />
            <p
              style={{
                fontSize: 24,
                fontWeight: "bold",
                color: "#ed1c23",
                margin: 0,
                marginTop: 20,
              }}
            >
              Welcome To Thurgood Marshall
            </p>
            <p class="text-muted">Anonymous Career Advancement</p>

            {/* <Lottie
              options={{ autoplay: true, loop: true, animationData: detective }}
              width={"100%"}
              height={350}
            /> */}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <Avatar
                  character="neutral"
                  width={60}
                  height={60}
                  style={{ margin: 10 }}
                />
                <Avatar
                  character="girl"
                  width={60}
                  height={60}
                  style={{ margin: 10 }}
                />
                <Avatar
                  character="boy"
                  width={60}
                  height={60}
                  style={{ margin: 10 }}
                />
              </div>
              <div>
                <Avatar
                  character="penguin"
                  width={60}
                  height={60}
                  style={{ margin: 10 }}
                />
                <Avatar
                  character="elephant"
                  width={60}
                  height={60}
                  style={{ margin: 10 }}
                />
                <Avatar
                  character="cat"
                  width={60}
                  height={60}
                  style={{ margin: 10 }}
                />
                <Avatar
                  character="bear"
                  width={60}
                  height={60}
                  style={{ margin: 10 }}
                />
              </div>
              <div>
                <Avatar
                  character="woman3"
                  width={90}
                  height={90}
                  style={{ margin: 10 }}
                />
                <Avatar
                  character="woman4"
                  width={90}
                  height={90}
                  style={{ margin: 10 }}
                />
              </div>
              <div>
                <Avatar
                  character="man2"
                  width={70}
                  height={70}
                  style={{ margin: 10 }}
                />
                <Avatar
                  character="woman6"
                  width={70}
                  height={70}
                  style={{ margin: 10 }}
                />
                <Avatar
                  character="man7"
                  width={70}
                  height={70}
                  style={{ margin: 10 }}
                />
              </div>
            </div>

            <div
              class="w-100"
              style={{ position: "fixed", bottom: 20, padding: 20 }}
            >
              <Link
                to="/login"
                class="btn btn-primary btn-block btn-lg text-white "
              >
                Log In
              </Link>
              <Link
                to="/signup"
                class="btn btn-primary btn-block btn-lg text-white"
              >
                Sign Up Free
              </Link>
            </div>
          </Container>
        </MobileOnlyView>
      </>
    );
  }
}

export default LandingScreen;

const SplitContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

const GoGigLogo = styled.img`
  width: 200px;
  border-radius: 8px;
  margin-bottom: 10px;
  @media (max-width: 500px) {
    margin-left: auto;
  }
`;

const Title = styled.h1`
  color: red;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  min-width: 200px;
`;

const Subtitle = styled.p`
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  min-width: 450px;
`;

const DividerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemContainer = styled.div`
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const Image = styled.img``;

const ItemTitle = styled.p`
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  color: #ed1c23;
  margin-top: 10px;
`;

const FooterText = styled.p`
  font-size: 20px;
  margin-top: 20px;
`;
