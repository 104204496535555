import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import API from "API";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import logo from "../images/logo/tmcf-logo-black.png";

class RegisterScreen extends Component {
  state = {
    form: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    loading: false,
    error: null,
    data: {},
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ ...this.state, loading: true });
    API.noOptionsPost("/UserProfiles", {
      email: this.state.form.email,
      password: this.state.form.password,
      firstName: this.state.form.fullName.split(" ")[0],
      lastName: this.state.form.fullName.split(" ")[1],
    })
      .then((response) => {
        this.setState({
          ...this.state,
          data: response.data,
          loading: false,
          error: null,
        });
      })
      .then(() =>
        API.noOptionsPost("/UserProfiles/login", {
          email: this.state.form.email,
          password: this.state.form.password,
        })
      )
      .then((response) => {
        let stringifiedToken = JSON.stringify(response.data);
        localStorage.setItem("userToken", stringifiedToken);
      })
      .then(() => {
        this.props.history.push("/split-path");
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          ...this.state,
          error: error,
          loading: false,
          data: {},
        });
      });
  };

  render() {
    return (
      <Container className="vh-100">
        <RegisterContainer>
          <RegisterContainerPane className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
            <StyledBanner
              src={require("images/modals/man-woman-vertical.png")}
            />
          </RegisterContainerPane>
          <RegisterContainerPane className="p-5 bg-white">
            <StyledLogo src={logo} />
            <p className="text-muted text-center">
              Anonymous Job Search&trade;
            </p>
            <button class="loginBtn loginBtn--facebook">
              Continue With Facebook
            </button>
            <Form>
              {/* <Form.Row className="mt-4">
                <Col>
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted">
                    Identity is anonymous until you
                  </Form.Text>
                </Col>
                <Col>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted">
                    choose to reveal it to a Recruiter
                  </Form.Text>
                </Col>
              </Form.Row> */}
              <Form.Row className="mt-4">
                <Col>
                  <Form.Label>First and Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First and Last Name"
                    name="fullName"
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted">
                    Identity is anonymous until you choose to reveal it to a
                    Recruiter
                  </Form.Text>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Label className="mt-4">Email address</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    onChange={this.handleChange}
                    placeholder="Email address"
                  />
                  <Form.Text className="text-muted">
                    Your email remains private
                  </Form.Text>
                  <Form.Label className="mt-4">Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted">
                    6 or more characters
                  </Form.Text>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={this.handleSubmit}
                    className="d-block ml-auto mr-auto"
                  >
                    Register
                  </Button>
                </Col>
              </Form.Row>
            </Form>
            <Link to="/login">
              <p className="mt-4 text-center text-muted">
                Already on Thurgood Marshall? Log In
              </p>
            </Link>
          </RegisterContainerPane>
        </RegisterContainer>
        <video id="background-video" loop autoPlay>
          <source src="videos/video.mp4" type="video/mp4" />
          <source src="videos/video.mp4" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </Container>
    );
  }
}

export default RegisterScreen;

const RegisterContainer = styled.div`
  background: white;
  max-width: 800px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  z-index: 2;
  border-top: 10px solid #ed1c23;
`;

const StyledBanner = styled.img`
  width: 300px;
`;

const RegisterContainerPane = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #edeff0;
`;

const StyledHeading = styled.h1`
  color: #0c3352;
  font-size: 48px !important;
  font-weight: bold;
`;

const StyledH3 = styled.h3`
  font-size: 18px;
  color: #000;
`;

const StyledLogo = styled.img`
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  display: block;
  margin-bottom: 0;
`;
