import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import API from "API";
import Select from "react-select";
import styled from "styled-components";
import { GeoSelectState, GeoSelectCity } from "GeoSelect";
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

class CurrentModal extends Component {
  state = {
    jobSearchStages: [],
    jobPositions: [],
    jobLevels: [],
    employmentEligibilities: [],
    companyTypes: [],
    degrees: [],
    industries: [],
    jobSearchStage: "",
    currentJobPosition: {},
    currentJobLevel: {},
    currentAnnualSalaryMax: 0,
    currentAnnualSalaryMin: 0,
    currentCompanyType: {},
    currentDegree: {},
    currentIndustry: {},
    currentCity: {},
    currentState: {},
    companyTypeEarly: 0,
    companyTypeLate: 0,
    companyTypeSmall: 0,
    companyTypeMedium: 0,
    companyTypeLarge: 0,
    jobPositionJobPositionSpecialties: [],
    selectedJobPositionSpecialty: {},
    jobPositionSpecialties: [],
    currentJobPositionSpecialty: {},
    currentJobPositionJobPositionSpecialties: [],
    hbcuAttended: {},
    hbcuAttendedId: "",
    hbcus: []
  };

  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter={
      "include": ["currentIndustry", "currentDegree", "currentCompanyType", "currentJobLevel", "currentJobPosition", "jobSearchStage", "hbcuAttended" ]
    }`
    ).then((response) => this.setState(response.data));

    API.get(`/JobSearchStages`).then((response) => {
      this.setState({
        jobSearchStages: response.data.sort(),
      });
    });

    API.get(`/HBCUAttendeds?filter={"order": "name ASC"}`).then((response) => {
      this.setState({
        hbcus: response.data,
      });
    });

    API.get(`/JobPositions?filter={"order": "name ASC" }`).then((response) => {
      this.setState({
        jobPositions: response.data.sort(),
      });
    });

    API.get(`/JobLevels?filter={"order": "priority ASC"}`).then((response) => {
      this.setState({
        jobLevels: response.data,
      });
    });

    API.get("/EmploymentEligibilities").then((response) => {
      this.setState({
        employmentEligibilities: response.data,
      });
    });

    API.get(`/JobPositionSpecialties?filter={"order": "name ASC" }`).then(
      (response) => {
        this.setState({
          jobPositionSpecialties: response.data.sort(),
        });
      }
    );

    API.get(`/CompanyTypes?filter={"order": "priority ASC"}`).then(
      (response) => {
        this.setState({
          companyTypes: response.data.sort(),
        });
      }
    );

    API.get(`/Degrees?filter={"order": "priority DESC"}`).then((response) => {
      this.setState({
        degrees: response.data.sort(),
      });
    });

    API.get(`/Industries?filter={"order": "name ASC" }`).then((response) => {
      let data = response.data;
      data.some(
        (item, idx) =>
          item.name == "I'm interested in any industry" &&
          data.unshift(data.splice(idx, 1)[0])
      );

      this.setState({
        industries: data,
      });
    });
  }

  screamChangeSingleTwo = (v, attr) => {
    this.setState({
      [attr]: v.value,
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    try {
      value = JSON.parse(value);
      this.setState({
        [name]: value,
      });
    } catch (e) {
      this.setState({
        [name]: value,
      });
    }
  };

  onSliderChange = (name, value) => {
    this.setState({
      currentAnnualSalaryMin: value,
    });
  };

  screamChangeSingle = (v, attr) => {
    this.setState({
      [attr]: v.value,
    });
    let jobPosition = v.value;
    let jobPositionSpecialties = this.state.jobPositionSpecialties;
    console.log(jobPosition);
    let filteredJobPositionSpecialties = jobPositionSpecialties.filter(
      (jobPositionSpecialty) => {
        return jobPosition.id === jobPositionSpecialty.jobPositionId;
      }
    );
    this.setState({
      currentJobPositionJobPositionSpecialties: filteredJobPositionSpecialties,
      currentJobPosition: jobPosition,
    });
  };

  handleAddJobPositionSpecialty = (e) => {
    let { name, value } = e.target;
    let jobPositionSpecialty = JSON.parse(value);
    // jobPositionSpecialty.experience = 0;
    this.setState({
      selectedJobPositionSpecialties:
        this.state.selectedJobPositionSpecialties.concat(jobPositionSpecialty),
    });
  };

  screamChange = (v) => {
    if (v) {
      let selectedSpecialties = v.map((selectedSpecialty) => {
        return {
          ...selectedSpecialty.value,
          experience: 0,
        };
      });

      this.setState({
        selectedJobPositionSpecialties: selectedSpecialties,
      });
    }
  };

  handleDesiredJobPosition = (e) => {
    let { name, value } = e.target;
    let jobPosition = JSON.parse(value);
    let jobPositionSpecialties = this.state.jobPositionSpecialties;
    let filteredJobPositionSpecialties = jobPositionSpecialties.filter(
      (jobPositionSpecialty) => {
        return jobPosition.id === jobPositionSpecialty.jobPositionId;
      }
    );
    this.setState({
      jobPositionJobPositionSpecialties: filteredJobPositionSpecialties,
      currentJobPosition: jobPosition,
    });
  };

  handleSubmit = () => {
    API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
      currentAnnualSalaryMax: this.state.currentAnnualSalaryMax,
      currentAnnualSalaryMin: this.state.currentAnnualSalaryMin,
      currentJobPositionId: this.state.currentJobPosition.id,
      currentAnnualSalaryPrivate: this.state.currentAnnualSalaryPrivate,
      currentJobLevelId: this.state.currentJobLevel.id,
      currentCompanyTypeId: this.state.currentCompanyType.id,
      currentDegreeId: this.state.currentDegree.id,
      currentIndustryId: this.state.currentIndustry.id,
      jobSearchStageId: this.state.jobSearchStage.id,
      currentCity: this.state.currentCity,
      currentState: this.state.currentState,
      companyTypeEarly: this.state.companyTypeEarly,
      companyTypeLarge: this.state.companyTypeLarge,
      companyTypeSmall: this.state.companyTypeSmall,
      companyTypeMedium: this.state.companyTypeMedium,
      companyTypeLate: this.state.companyTypeLate,
      employmentEligibilityId: this.state.employmentEligibilityId,
      currentJobPositionSpecialtyId: this.state.currentJobPositionSpecialty.id,
      jobSearchStageId: this.state.jobSearchStage.id,
      hbcuAttendedId: this.state.hbcuAttended.id
    }).then((response) => {
      this.props.getFreshData();
      this.props.onboard
        ? this.props.editModal("desires")
        : this.props.closeModal();
    });
  };

  handleSelectChange = (inputVals, opt) => {
    let { name } = opt;
    this.setState({
      [name]: inputVals,
    });
  };

  render() {
    return (
      <Modal
        show={this.props.displayModal === "current"}
        dialogClassName="modal-65w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Body>
          <ModalContainer>
            <img
              style={{
                position: "absolute",
                zIndex: 100,
                width: 175,
                borderRadius: 8,
                left: 30,
                top: 30,
              }}
              src={require("images/logo/tmcf-logo-black.png")}
            />
            <ModalColumn className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
              <img
                style={{
                  width: 450,
                  bottom: 0,
                }}
                src={require("images/modals/gogig-career-desires.png")}
              />
            </ModalColumn>
            <ModalColumn className="p-5 w-100 bg-white">
              <Row>
                <Col>
                  <h1 style={{ fontWeight: "normal" }}>
                    Your Current Career Experiences
                  </h1>
                  <p>Tell us where you are.</p>
                </Col>
              </Row>
              <Form>
                {/* <Form.Row>
                  <Col>
                    <Form.Label>
                      Where are you currently in your job search?
                    </Form.Label>
                    <Form.Control
                      name="jobSearchStage"
                      onChange={this.handleChange}
                      as="select"
                      value={JSON.stringify(this.state.jobSearchStage)}
                    >
                      <option disabled value={JSON.stringify({})}>
                        -- select an option --
                      </option>
                      {this.state.jobSearchStages.map(jobSearchStage => (
                        <option value={JSON.stringify(jobSearchStage)}>
                          {jobSearchStage.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Row> */}

                <Form.Row className="">
                  <Col>
                    <Form.Label>
                      Current (or most recent) Position Type
                    </Form.Label>
                    {/* <Form.Control
                      as="select"
                      onChange={this.handleDesiredJobPosition}
                      name="currentJobPosition"
                      value={JSON.stringify(this.state.currentJobPosition)}
                    >
                      <option disabled value={JSON.stringify({})}>
                        -- select an option --
                      </option>
                      {this.state.jobPositions.map(jobPosition => (
                        <option value={JSON.stringify(jobPosition)}>
                          {jobPosition.name}
                        </option>
                      ))}
                    </Form.Control> */}
                    <Select
                      onChange={(v) =>
                        this.screamChangeSingle(v, "currentJobPosition")
                      }
                      placeholder="Enter your current (or most recent) Position Type"
                      styles={{
                        container: (base, state) => ({
                          ...base,
                          width: "100%",
                          outline: "none",
                          padding: 0,
                        }),
                        valueContainer: (base, state) => ({
                          ...base,
                          padding: 0,
                        }),
                        control: (base, state) => ({
                          ...base,
                          border: "none",
                          borderBottom: "1px solid silver",
                          outline: "none",
                          padding: 0,
                          margin: 0,
                          borderRadius: 0,
                          outline: "none",
                        }),
                      }}
                      defaultValue={{
                        label: this.state.currentJobPosition.name,
                        value: this.state.currentJobPosition,
                      }}
                      isMulti={false}
                      options={this.state.jobPositions.map((jobPosition) => {
                        return {
                          label: jobPosition.name,
                          value: jobPosition,
                        };
                      })}
                    />
                  </Col>
                </Form.Row>

                {Object.keys(this.state.currentJobPosition).length !== 0 && (
                  <Form.Row className="mt-4">
                    <Col>
                      <Form.Label>
                        What specialty within "
                        {this.state.currentJobPosition.name}" are you working
                        in?
                      </Form.Label>
                      {/* <Form.Control
                        as="select"
                        name="currentJobPositionSpecialty"
                        onChange={this.handleChange}
                        value={JSON.stringify(
                          this.state.currentJobPositionSpecialty
                        )}
                      >
                        {this.state.jobPositionJobPositionSpecialties.map(
                          jobPositionSpecialty => (
                            <option
                              value={JSON.stringify(jobPositionSpecialty)}
                            >
                              {jobPositionSpecialty.name}
                            </option>
                          )
                        )}
                      </Form.Control> */}
                      <Select
                        styles={{
                          container: (base, state) => ({
                            ...base,
                            width: "100%",
                          }),
                          valueContainer: (base, state) => ({
                            ...base,
                            padding: 0,
                          }),
                          control: (base, state) => ({
                            ...base,
                            border: "none",
                            borderBottom: "1px solid silver",
                            outline: "none",
                            padding: 0,
                            margin: 0,
                            borderRadius: 0,
                          }),
                        }}
                        onChange={(v) =>
                          this.screamChangeSingleTwo(
                            v,
                            "currentJobPositionSpecialty"
                          )
                        }
                        isMulti={false}
                        defaultValue={{
                          label: this.state.currentJobPositionSpecialty.name,
                          value: this.state.currentJobPositionSpecialty,
                        }}
                        options={this.state.currentJobPositionJobPositionSpecialties.map(
                          (jobPositionSpecialty) => {
                            return {
                              label: jobPositionSpecialty.name,
                              value: jobPositionSpecialty,
                            };
                          }
                        )}
                      />
                    </Col>
                  </Form.Row>
                )}

                <Form.Row className="mt-4">
                  <Col>
                    <Form.Label>
                      Current (or most recent) industry you worked in
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="currentIndustry"
                      onChange={this.handleChange}
                      value={JSON.stringify(this.state.currentIndustry)}
                    >
                      <option disabled value={JSON.stringify({})}>
                        Enter your current (or most recent) industry
                      </option>
                      {this.state.industries.map((industry) => (
                        <option value={JSON.stringify(industry)}>
                          {industry.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Row>

                <Form.Row className="mt-4">
                  <Col>
                    <Form.Label>Current (or most recent) Job Level</Form.Label>
                    <Form.Control
                      as="select"
                      name="currentJobLevel"
                      value={JSON.stringify(this.state.currentJobLevel)}
                      onChange={this.handleChange}
                    >
                      <option disabled value={JSON.stringify({})}>
                        -- select an option --
                      </option>
                      {this.state.jobLevels.map((jobLevel) => (
                        <option value={JSON.stringify(jobLevel)}>
                          {jobLevel.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Row>

                <Form.Row className="mt-5">
                  {!this.state.currentAnnualSalaryPrivate && (
                    <Col>
                      <Form.Row>
                        <Form.Label>
                          What is the most recent total annual compensation you
                          earned from your last (or current) job?
                        </Form.Label>
                        <SliderValueContainer>
                          <SliderValue>
                            {this.state.currentAnnualSalaryMin === 500000
                              ? "$" +
                                this.state.currentAnnualSalaryMin.toLocaleString() +
                                "+"
                              : "$" +
                                this.state.currentAnnualSalaryMin.toLocaleString()}
                          </SliderValue>
                        </SliderValueContainer>
                      </Form.Row>
                      <Slider
                        min={0}
                        max={500000}
                        step={5000}
                        onChange={(d) =>
                          this.onSliderChange("currentAnnualSalary", d)
                        }
                        marks={{
                          0: "$0",
                          500000: "$500,000+",
                        }}
                        defaultValue={this.state.currentAnnualSalaryMin}
                        tipFormatter={(value) => `${value}%`}
                        railStyle={{
                          backgroundColor: "#f5f5f5",
                          height: 10,
                        }}
                        trackStyle={[
                          { backgroundColor: "#D3202A", height: 10 },
                          { backgroundColor: "#D3202A", height: 10 },
                        ]}
                        handleStyle={[
                          {
                            backgroundColor: "#D3202A",
                            borderColor: "#D3202A",
                            width: 17,
                            height: 17,
                            top: 6,
                          },
                          {
                            backgroundColor: "#D3202A",
                            borderColor: "#D3202A",
                            width: 17,
                            height: 17,
                            top: 6,
                          },
                        ]}
                        dotStyle={{
                          display: "none",
                        }}
                      />
                    </Col>
                  )}
                </Form.Row>

                <Form.Row className="mt-4">
                  <Col>
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check
                        name="currentAnnualSalaryPrivate"
                        onChange={() =>
                          this.setState({
                            currentAnnualSalaryPrivate:
                              !this.state.currentAnnualSalaryPrivate,
                          })
                        }
                        type="checkbox"
                        checked={this.state.currentAnnualSalaryPrivate}
                        label="Prefer not to say"
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row className="mt-2">
                  <Col>
                    {" "}
                    <Form.Label>Company Type Experience</Form.Label>
                  </Col>
                </Form.Row>

                <Form.Row className="mt-1">
                  <Col>
                    <div>
                      <p style={{ margin: 0, color: "#000", padding: 0 }}>
                        Early Stage Startup
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#999",
                          fontSize: 15,
                        }}
                      >
                        You wear multiple hats to help the company get off the
                        ground
                      </p>
                    </div>
                    <SliderValueContainer>
                      <SliderValue>
                        {this.state.companyTypeEarly === 25
                          ? "25+"
                          : this.state.companyTypeEarly}{" "}
                        {this.state.companyTypeEarly === 1 ? "year" : "years"}
                      </SliderValue>
                    </SliderValueContainer>
                    <Slider
                      min={0}
                      max={25}
                      onChange={(v) =>
                        this.setState({
                          companyTypeEarly: v,
                        })
                      }
                      marks={{
                        0: "0",
                        25: "25 years+",
                      }}
                      defaultValue={this.state.companyTypeEarly}
                      allowCross={false}
                      tipFormatter={(value) => `{value} years`}
                      railStyle={{
                        backgroundColor: "#f5f5f5",
                        height: 10,
                      }}
                      trackStyle={[
                        { backgroundColor: "#D3202A", height: 10 },
                        { backgroundColor: "#D3202A", height: 10 },
                      ]}
                      handleStyle={[
                        {
                          backgroundColor: "#D3202A",
                          borderColor: "#D3202A",
                          width: 17,
                          height: 17,
                          top: 6,
                        },
                        {
                          backgroundColor: "#D3202A",
                          borderColor: "#D3202A",
                          width: 17,
                          height: 17,
                          top: 6,
                        },
                      ]}
                      dotStyle={{
                        display: "none",
                      }}
                    />
                  </Col>
                </Form.Row>

                <Form.Row className="mt-4">
                  <Col>
                    <div>
                      <p style={{ margin: 0, color: "#000", padding: 0 }}>
                        Later Stage Startup
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#999",
                          fontSize: 15,
                        }}
                      >
                        You refine the processes and/or product to help the
                        company scale
                      </p>
                    </div>
                    <SliderValueContainer>
                      <SliderValue>
                        {this.state.companyTypeLate === 25
                          ? "25+"
                          : this.state.companyTypeLate}{" "}
                        {this.state.companyTypeLate === 1 ? "year" : "years"}
                      </SliderValue>
                    </SliderValueContainer>
                    <Slider
                      min={0}
                      max={25}
                      onChange={(v) =>
                        this.setState({
                          companyTypeLate: v,
                        })
                      }
                      marks={{
                        0: "0",
                        25: "25 years+",
                      }}
                      defaultValue={this.state.companyTypeLate}
                      allowCross={false}
                      tipFormatter={(value) => `{value} years`}
                      railStyle={{
                        backgroundColor: "#f5f5f5",
                        height: 10,
                      }}
                      trackStyle={[
                        { backgroundColor: "#D3202A", height: 10 },
                        { backgroundColor: "#D3202A", height: 10 },
                      ]}
                      handleStyle={[
                        {
                          backgroundColor: "#D3202A",
                          borderColor: "#D3202A",
                          width: 17,
                          height: 17,
                          top: 6,
                        },
                        {
                          backgroundColor: "#D3202A",
                          borderColor: "#D3202A",
                          width: 17,
                          height: 17,
                          top: 6,
                        },
                      ]}
                      dotStyle={{
                        display: "none",
                      }}
                    />
                  </Col>
                </Form.Row>

                <Form.Row className="mt-4">
                  <Col>
                    <div>
                      <p style={{ margin: 0, color: "#000", padding: 0 }}>
                        Small Established (50-200 Employees)
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#999",
                          fontSize: 15,
                        }}
                      >
                        You support an established enterprise with focus on
                        retention and opportunity
                      </p>
                    </div>
                    <SliderValueContainer>
                      <SliderValue>
                        {this.state.companyTypeSmall === 25
                          ? "25+"
                          : this.state.companyTypeSmall}{" "}
                        {this.state.companyTypeSmall === 1 ? "year" : "years"}
                      </SliderValue>
                    </SliderValueContainer>
                    <Slider
                      min={0}
                      max={25}
                      onChange={(v) =>
                        this.setState({
                          companyTypeSmall: v,
                        })
                      }
                      marks={{
                        0: "0",
                        25: "25 years+",
                      }}
                      defaultValue={this.state.companyTypeSmall}
                      allowCross={false}
                      tipFormatter={(value) => `{value} years`}
                      railStyle={{
                        backgroundColor: "#f5f5f5",
                        height: 10,
                      }}
                      trackStyle={[
                        { backgroundColor: "#D3202A", height: 10 },
                        { backgroundColor: "#D3202A", height: 10 },
                      ]}
                      handleStyle={[
                        {
                          backgroundColor: "#D3202A",
                          borderColor: "#D3202A",
                          width: 17,
                          height: 17,
                          top: 6,
                        },
                        {
                          backgroundColor: "#D3202A",
                          borderColor: "#D3202A",
                          width: 17,
                          height: 17,
                          top: 6,
                        },
                      ]}
                      dotStyle={{
                        display: "none",
                      }}
                    />
                  </Col>
                </Form.Row>

                <Form.Row className="mt-4">
                  <Col>
                    <div>
                      <p style={{ margin: 0, color: "#000", padding: 0 }}>
                        Medium (201-800 Employees)
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#999",
                          fontSize: 15,
                        }}
                      >
                        You support a mid-size enterprise with focus to grow or
                        maintain market share
                      </p>
                    </div>{" "}
                    <SliderValueContainer>
                      <SliderValue>
                        {this.state.companyTypeMedium === 25
                          ? "25+"
                          : this.state.companyTypeMedium}{" "}
                        {this.state.companyTypeMedium === 1 ? "year" : "years"}
                      </SliderValue>
                    </SliderValueContainer>
                    <Slider
                      min={0}
                      max={25}
                      onChange={(v) =>
                        this.setState({
                          companyTypeMedium: v,
                        })
                      }
                      marks={{
                        0: "0",
                        25: "25 years+",
                      }}
                      defaultValue={this.state.companyTypeMedium}
                      allowCross={false}
                      tipFormatter={(value) => `{value} years`}
                      railStyle={{
                        backgroundColor: "#f5f5f5",
                        height: 10,
                      }}
                      trackStyle={[
                        { backgroundColor: "#D3202A", height: 10 },
                        { backgroundColor: "#D3202A", height: 10 },
                      ]}
                      handleStyle={[
                        {
                          backgroundColor: "#D3202A",
                          borderColor: "#D3202A",
                          width: 17,
                          height: 17,
                          top: 6,
                        },
                        {
                          backgroundColor: "#D3202A",
                          borderColor: "#D3202A",
                          width: 17,
                          height: 17,
                          top: 6,
                        },
                      ]}
                      dotStyle={{
                        display: "none",
                      }}
                    />
                  </Col>
                </Form.Row>

                <Form.Row className="mt-4">
                  <Col>
                    <div>
                      <p style={{ margin: 0, color: "#000", padding: 0 }}>
                        Large (801+ Employees)
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "#999",
                          fontSize: 15,
                        }}
                      >
                        You support a large enterprise with focus to turn new
                        business models into revenue generating opportunties
                      </p>
                    </div>{" "}
                    <SliderValueContainer>
                      <SliderValue>
                        {this.state.companyTypeLarge === 25
                          ? "25+"
                          : this.state.companyTypeLarge}{" "}
                        {this.state.companyTypeLarge === 1 ? "year" : "years"}
                      </SliderValue>
                    </SliderValueContainer>
                    <Slider
                      min={0}
                      max={25}
                      onChange={(v) =>
                        this.setState({
                          companyTypeLarge: v,
                        })
                      }
                      marks={{
                        0: "0",
                        25: "25 years+",
                      }}
                      defaultValue={this.state.companyTypeLarge}
                      allowCross={false}
                      tipFormatter={(value) => `{value} years`}
                      railStyle={{
                        backgroundColor: "#f5f5f5",
                        height: 10,
                      }}
                      trackStyle={[
                        { backgroundColor: "#D3202A", height: 10 },
                        { backgroundColor: "#D3202A", height: 10 },
                      ]}
                      handleStyle={[
                        {
                          backgroundColor: "#D3202A",
                          borderColor: "#D3202A",
                          width: 17,
                          height: 17,
                          top: 6,
                        },
                        {
                          backgroundColor: "#D3202A",
                          borderColor: "#D3202A",
                          width: 17,
                          height: 17,
                          top: 6,
                        },
                      ]}
                      dotStyle={{
                        display: "none",
                      }}
                    />
                  </Col>
                </Form.Row>

                {/* <Form.Row className="mt-4">
                  <Col>
                    <Form.Label>
                      What type of company are you working at?
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      value={JSON.stringify(this.state.currentCompanyType)}
                      name="currentCompanyType"
                    >
                      <option disabled value={JSON.stringify({})}>
                        -- select an option --
                      </option>
                      {this.state.companyTypes.map(companyType => (
                        <option value={JSON.stringify(companyType)}>
                          {companyType.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Row> */}

                <Form.Row>
                  <Col>
                    <Form.Label className="mt-4">
                      What best describes where you're at in your job search?
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="jobSearchStage"
                      onChange={this.handleChange}
                      value={JSON.stringify(this.state.jobSearchStage)}
                    >
                      <option disabled value="">
                        Where are you currently in your job search?
                      </option>
                      {this.state.jobSearchStages.map((jobSearchStage) => (
                        <option value={JSON.stringify(jobSearchStage)}>
                          {jobSearchStage.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Row>

                <Form.Row className="mt-4">
                  <Col>
                    <Form.Label>What is your current home location?</Form.Label>
                    <GeoSelectCity
                      name="currentCity"
                      isMulti={false}
                      handleChange={this.handleSelectChange}
                      value={this.state.currentCity}
                    />
                  </Col>
                </Form.Row>

                <Form.Row className="mt-4">
                  <Col>
                    <Form.Label>
                      Highest level of education obtained?
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      value={JSON.stringify(this.state.currentDegree)}
                      name="currentDegree"
                    >
                      <option disabled value={JSON.stringify({})}>
                        What's your highest level of education
                      </option>
                      {this.state.degrees.map((degree) => (
                        <option value={JSON.stringify(degree)}>
                          {degree.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Row>

                <Form.Row className="mt-4">
                  <Col>
                    <Form.Label>
                      HBCU Attended
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      value={JSON.stringify(this.state.hbcuAttended)}
                      name="hbcuAttended"
                    >
                      <option disabled value={JSON.stringify({})}>
                        What's your HBCU?
                      </option>
                      {this.state.hbcus.map((hbcu) => (
                        <option value={JSON.stringify(hbcu)}>
                          {hbcu.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col>
                    <Form.Label className="mt-4">
                      Employment Eligibility
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="employmentEligibilityId"
                      onChange={this.handleChange}
                      value={this.state.employmentEligibilityId}
                    >
                      <option disabled value="">
                        Select employment status
                      </option>
                      {this.state.employmentEligibilities.map((ee) => (
                        <option value={ee.id}>{ee.title}</option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Row>

                <Form.Row className="mt-5">
                  <Col>
                    <Button
                      variant="outline-primary"
                      className="mr-1"
                      onClick={this.props.closeModal}
                    >
                      Exit
                    </Button>
                    <Button className="float-right" onClick={this.handleSubmit}>
                      {this.props.onboard ? "Next" : "Update"}
                    </Button>
                  </Col>
                </Form.Row>
              </Form>
            </ModalColumn>
          </ModalContainer>
        </Modal.Body>
      </Modal>
    );
  }
}

export default CurrentModal;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  margin: -16px;
`;
const ModalColumn = styled.div`
  background: #edeff0;
`;

const SliderValueContainer = styled.p`
  margin: 0;
  padding: 0;
  margin-left: auto;
  font-weight: 500;
  color: #020000;
`;
const SliderValue = styled.p`
  margin: 0;
  padding: 0;
`;
