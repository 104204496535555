import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LoginIcon from '@material-ui/icons/Input';

import classes from './index.module.css';

function NavMenuTemplate(props) {
  const {
    signInLink,
    desktopLogo,
    mobileLogo,
    navLinkItems,
  } = props;
  const [navbarOpacity, changeNavbarOpacity] = useState('rgba(255,255,255,0)');
  const [navSocials, changeNavSocials] = useState('block');
  const [navSignIn, changeNavSignIn] = useState('none');
  const [navThreshold, changeNavThreshold] = useState(false);
  const pathname = window.location.pathname.replace('/', '');

  const iconStructure = {
    login: () => (
      <LoginIcon
        style={{
          fill: navThreshold ? '#A2171F' : '#fff',
        }}
        className={classes.navIcon}
      />
    ),
    resource: () => (
      <MenuBookIcon
        style={{
          fill: navThreshold ? '#A2171F' : '#fff',
        }}
        className={classes.navIcon}
      />
    ),
    default: (icon) => (
      <img
        className={classes.iconStyle}
        src={icon}
        alt="Facebook Small Logo"
      />
    )
  }

  const linkStructure = {
    external: (text, link, icon, iconType) => (
      <a
        style={{
          color: navThreshold ? '#000' : '#fff',
        }}
        className={classes.linkNeutral}
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        <div
          className={classes.linkContainer}
        >
          {
            text ? (
              <Typography
                style={{
                  fontSize: '0.875rem',
                  fontFamily: 'Open Sans',
                  marginRight: '10px',
                }}
              >
                { text }
              </Typography>
            ) : null
          }
          {
            iconType ? iconStructure[iconType](icon) : null
          }
        </div>
      </a>
    ),
    internal: (text, link, icon, iconType) => (
      <Link
        href={link}
      >
        <div
          className={classes.linkContainer}
        >
          {
            text ? (
              <Typography
                className={classes.linkNeutral}
                style={{
                  color: navThreshold ? '#000' : '#fff',
                  fontSize: '0.875rem',
                  fontFamily: 'Open Sans',
                  marginRight: '10px',
                }}
              >
                { text }
              </Typography>
            ) : null
          }
          {
            iconType ? iconStructure[iconType](icon) : null
          }
        </div>
      </Link>
    ),
  }

  const buildNavLinks = () => {
    const result = navLinkItems ? navLinkItems.map((items) => {
      const {
        linkType,
        icon,
        link,
        text,
        iconType,
      } = items;
      return linkStructure[linkType](text, link, icon, iconType);
    }) : null;
    return result;
  }

  return (
    <nav
      className={classes.navContainer}
      style={{
        background: navbarOpacity,
      }}
    >
      <Grid
        container
        className={classes.navFullHeight}
      >
        <Grid
          item
          xs={0}
          md={2}
        />
        <Grid
          className={classes.navFullHeight}
          item
          xs={12}
          md={4}
        >
          <Box
            className={classes.logoContainer}
          >
            <Link
              href="/"
            >
              <img
                className={clsx(classes.logo, classes.desktopPadding)}
                src={desktopLogo}
                alt=""
              />
            </Link>
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          md={4}
          className={classes.navLinkContainer}
        >
          {
            buildNavLinks()
          }
        </Grid>
        <Grid
          item
          xs={0}
          md={2}
        />
      </Grid>
    </nav>
  );
}

NavMenuTemplate.propTypes = {};

export default NavMenuTemplate;
