import React, { Component } from "react";
import API from "API";
import styled from "styled-components";
import { Row, Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as SearchIcon } from "images/icons/Search.svg";
import { ReactComponent as SpeakerIcon } from "images/icons/Feedback.svg";
import { ReactComponent as NotificationIcon } from "images/icons/Notifications.svg";
import { BrowserView, MobileOnlyView } from "react-device-detect";
import Dropzone from "react-dropzone";
import axios from "axios";
import { Avatar } from "Stories/Avatars.stories.js";
import moment from "moment-timezone";

class UserFeed extends Component {
  state = {
    feed: [],
    profileComplete: false,
    profilePhoto: "",
    message: "",
  };

  componentDidMount() {
    API.get(`/UserFeeds?filter={"order": "createdAt DESC"}`).then(
      (response) => {
        console.log("Feed Data: ", response.data);
        this.setState({
          feed: response.data,
        });
      }
    );

    // var myInt = setInterval(() => {
    //   API.get(`/UserProfiles/${this.props.userToken.userId}`).then(response => {
    //     this.setState({
    //       profileComplete: response.data.profileComplete
    //     });
    //   });
    // }, 2000);
    // this.setState({
    //   myInt: myInt
    // });
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  uploadFile = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      let formData = new FormData();
      formData.append("newFormData", file);
      axios
        .post(
          "https://my.gogig.com:4000/api/Attachments/gogig-v3/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
            profilePhoto:
              "https://my.gogig.com:4000/api/Attachments/gogig-v3/download/" +
              response.data.result.files.newFormData[0].name,
          });
          this.setState({
            profilePhoto:
              "https://my.gogig.com:4000/api/Attachments/gogig-v3/download/" +
              response.data.result.files.newFormData[0].name,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  componentDidUpdate() {
    // API.get(`/UserProfiles/${this.props.userToken.userId}`).then((response) => {
    //   this.setState({
    //     feed: response.data,
    //     profileComplete: response.data.profileComplete,
    //   });
    // });
  }

  handleSubmit = () => {
    API.post(`/UserFeeds`, {
      message: this.state.message,
      attachment: this.state.profilePhoto,
      userId: this.props.userToken.userId,
    }).then(() => {
      this.setState({
        message: "",
        profilePhoto: "",
      });
      API.get(`/UserFeeds?filter={"order": "createdAt DESC"}`).then(
        (response) => {
          this.setState({
            feed: response.data,
          });
        }
      );
    });
  };

  handleDateValidation = (cstDateString) => {
    const cstMoment = moment.tz(cstDateString, 'America/Chicago');
    const today = moment();

    // Compare the day, month, and year of the CST date and today's date
    if(cstMoment.date() <= today.date() && cstMoment.month() <= today.month() && cstMoment.year() <= today.year()) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    clearInterval(this.state.myInt);
  }

  renderStatusMessage() {
    return this.props.profileComplete ? (
      <CompleteProfileCard>
        <CompleteProfileCardTitleContainer>
          <SpeakerIcon />
          <CompleteProfileCardTitle>You're all set!</CompleteProfileCardTitle>
        </CompleteProfileCardTitleContainer>
        <CompleteProfileCardDescription>
          You've succesfully completed your profile! Check your culture report
          under Competency Evaluation Snapshot to learn more about how hiring
          managers will see you.
        </CompleteProfileCardDescription>
        <Row>
          <Col>
            <Link to="/profile">
              <Button className="float-right">View Profile</Button>
            </Link>
          </Col>
        </Row>
      </CompleteProfileCard>
    ) : (
      <CompleteProfileCard>
        <CompleteProfileCardTitleContainer>
          <SearchIcon />

          <CompleteProfileCardTitle>
            Your profile is not discoverable to Hiring Companies within TMCF
            HBCU Alumni Career Connect
          </CompleteProfileCardTitle>
        </CompleteProfileCardTitleContainer>
        <CompleteProfileCardDescription>
          Your profile is not complete. In order for you to become discoverable
          to Hiring Companies in TMCF HBCU Alumni Career Connect and view your
          Professional Competency Evaluator, you must complete your profile.
        </CompleteProfileCardDescription>
        <Link to="/profile?onboard=true">
          <Button
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: 0,
            }}
          >
            View Profile
          </Button>
        </Link>
      </CompleteProfileCard>
    );
  }

  render() {
    return (
      <div>
        {!this.props.recruiterMode && this.renderStatusMessage()}

        <CompleteProfileCard style={{ marginTop: 20 }}>
          <CompleteProfileCardTitleContainer>
            <NotificationIcon />
            <CompleteProfileCardTitle>
              Enable Notifications
            </CompleteProfileCardTitle>
          </CompleteProfileCardTitleContainer>
          <CompleteProfileCardDescription>
            We don't communicate a lot to you, but when we do, it's important
            because you have a match!
          </CompleteProfileCardDescription>
          <Row>
            <Col>
              <Link to="/settings/notifications">
                <Button className="float-right">Go To Notifications</Button>
              </Link>
            </Col>
          </Row>
        </CompleteProfileCard>

        {/* {this.props.location.pathname !== "/" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              position: "relative",
              alignItems: "flex-start",
              flexDirection: "column",
              background: "white",
              marginBottom: 20,
              padding: 15,
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  flex: "1",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <textarea
                  onChange={this.handleChange}
                  value={this.state.message}
                  name="message"
                  placeholder="What would you like to post?"
                  style={{
                    width: "100%",
                    outline: "none",
                    border: "none",
                    resize: "none",
                  }}
                ></textarea>
              </div>
              {this.state.profilePhoto === "" ? (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100px",
                    alignItems: "flex-start",
                    flex: "0 0 auto",
                  }}
                >
                  <Dropzone onDrop={this.uploadFile}>
                    {({ getRootProps, getInputProps }) => (
                      <StyledSection>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p style={{ margin: 0 }} className="text-muted">
                            Add Attachment
                          </p>
                        </div>
                      </StyledSection>
                    )}
                  </Dropzone>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    alignItems: "flex-start",
                    flex: "0 0 auto",
                  }}
                >
                  <div>
                    <p
                      onClick={() => this.setState({ profilePhoto: "" })}
                      style={{ textAlign: "right", cursor: "pointer" }}
                    >
                      Remove
                    </p>
                  </div>
                  <ProfilePhoto src={this.state.profilePhoto} />
                </div>
              )}

              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  width: "100%",
                  alignItems: "flex-end",
                  flex: "0 0 auto",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button onClick={this.handleSubmit} className="float-right">
                  Post
                </Button>
              </div>
            </div>
          </div>
        )} */}

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage src={require("images/icons/Avatar/Man 7.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Dr. Williams</NewsFeedCardName>
              <NewsFeedCardJob>President and CEO, TMCF</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Pinned</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Our alumni network is a powerful part of the TMCF experience.
              We’re here to organize and engage with you, and connect you with
              employers to help you advance your career.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/tmcf-head.MOV" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard
          style={{
            display: this.handleDateValidation("2023-11-17 11:00:00") ? "block" : "none",
          }}
        >
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Woman 4.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Precious Dorch-Robinson</NewsFeedCardName>
              <NewsFeedCardJob>Brand Marketing Manager</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>November 17, 2023 at 11:00 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              At TMCF, we’re changing the world one leader at a time and our programming prepares the next generation of changemakers. 
              <br /> <br />
              We’d like to celebrate our TMCF alum, North Carolina A&T State University alum and entrepreneur, Albert Edney! This ambitious changemaker was a three-time LI attendee and he used our programming to help him excel in his future endeavors. He’s interested in a career in aerospace and space architecture. Albert is attending Johns Hopkins University and earning a Ph.D. in Civil Engineering.
              <br /> <br />
              Let’s celebrate Albert and wish him well as he attends grad school.
              <br /> <br />
              If you’re a TMCF Scholar, share your story. We’d love to hear about your career journey. Sharing your story can inspire our next generation of leaders.
              <br /> <br />
              Share your story here: <a href="https://tm-cf.org/share-your-story" target="_blank" rel="noopener noreferrer">tm-cf.org/share-your-story</a>
              <br /> <br />
              <img
                width="100%"
                src="/images/img-albert-edney-final-quote.png"
                alt="TMCF Albert Edney Final Quote"
              />
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard
          style={{
            display: this.handleDateValidation("2023-11-16 11:00:00") ? "block" : "none",
          }}
        >
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Woman 4.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Precious Dorch-Robinson</NewsFeedCardName>
              <NewsFeedCardJob>Brand Marketing Manager</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>November 16, 2023 at 11:00 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              <strong><span role="img" aria-label="congrats">🎉</span> Exciting news! Applications for The Pitch™ 2024 are NOW OPEN! </strong>
              <br /> <br />
              If you're ready to dive into the world of innovation and entrepreneurship, this is your chance to shine. Don't miss out on this incredible opportunity and apply here: <a href="https://bit.ly/thepitch24" target="_blank" rel="noopener noreferrer">bit.ly/thepitch24</a>
              <br /> <br />
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/qLInMAxHmfs?si=LjWi6zd2tvRsVaRB"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen>
            </iframe>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard
          style={{
            display: this.handleDateValidation("2023-11-15 11:00:00") ? "block" : "none",
          }}
        >
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Woman 4.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Precious Dorch-Robinson</NewsFeedCardName>
              <NewsFeedCardJob>Brand Marketing Manager</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>November 15, 2023 at 11:00 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              <strong>Welcome to the Unsaid Truth podcast, presented by Thurgood Marshall College Fund.</strong>
              <br /> <br />
              Do you prefer to work fully remote? Or does a hybrid model appeal to you more?
              <br /> <br />
              This week our hosts were joined by two former TMCF Medtronic Scholars to talk about remote vs. hybrid work models. Nicholas Darby and Takeria Chapman attended college and entered the workforce at a time when remote work was at its peak.
              <br /> <br />
              In this episode, they’re going to share their opinions about whether they prefer a fully remote, hybrid, or fully ‘in-office’ job.
              <br /> <br />
              Tune into the Unsaid Truth podcast to learn how Jerrin, Khadija, Takeria, and Nicholas prefer to work and steal their cheat codes to protect their mental health, make friends, and stay productive both at home and at the office. 
              <br /> <br />
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/E6mW5QXzK9I"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen>
            </iframe>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Woman 4.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Precious Dorch-Robinson</NewsFeedCardName>
              <NewsFeedCardJob>Brand Marketing Manager</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>November 14, 2023 at 10:00 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Are you looking for an internship? TMCF has internships open for summer 2024 for HBCU students majoring in STEM, business and finance. It's time to invest in yourself and learn new skills this summer.
              <br /> <br />
              <img
                width="100%"
                src="/images/img-summer-internships-2024.jpg"
                alt="TMCF Summer Internships 2024"
              />
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Woman 4.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Precious Dorch-Robinson</NewsFeedCardName>
              <NewsFeedCardJob>Brand Marketing Manager</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>November 13, 2023 at 10:00 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              <strong>Welcome to the Unsaid Truth podcast, presented by Thurgood Marshall College Fund.</strong>
              <br /> <br />
              We’d like to celebrate our TMCF alum, Hampton University &North Carolina A&T State University alum,, Daryl Riley! This entrepreneur attended The Pitch, Leadership Institute and he was a TMCF USDA intern.  His experience at The Pitch helped him excel with his fashion tech business, TENDAJI.
              <br /> <br />
              Let’s celebrate Daryl and wish him well as he continues his entrepreneurial journey. He’s is a finalist in the 2023 Black Ambition Competition. Click the link in our story, to vote for Daryl. Voting closes on Wednesday, October 18!
              <br /> <br />
              If you’re a TMCF Scholar, share your story. We’d love to hear about your career journey. Sharing your story can inspire our next generation of leaders.
              <br /> <br />
              Share your story here: <a href="https://tm-cf.org/share-your-story" target="_blank" rel="noopener noreferrer">tm-cf.org/share-your-story</a>
              <br /> <br />
              <img
                width="100%"
                src="/images/img-daryl-final-quote.png"
                alt="TMCF Daryl Riley Final Quote"
              />
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Woman 4.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Precious Dorch-Robinson</NewsFeedCardName>
              <NewsFeedCardJob>Brand Marketing Manager</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>October 27, 2023 at 10:00 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              The So Ambitious HBCU Tour is back for the fall, and our next stop is Norfolk State University!
              <br /> <br />
              Are you ready to unlock your entrepreneurial potential? Join us for an inspiring weekend filled with top-notch entrepreneurial training, mentorship opportunities, access to valuable resources, and the chance to pitch your business idea for potential funding of up to 15k! Register here: <a href="http://bit.ly/nsusoambitious23" target="_blank" rel="noopener noreferrer">bit.ly/nsusoambitious23</a>
              <br /> <br />
              <img
                width="100%"
                src="/images/img-tmcf-norfolk.png"
                alt="TMCF Norfolk State University Tour"
              />
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Woman 4.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Precious Dorch-Robinson</NewsFeedCardName>
              <NewsFeedCardJob>Brand Marketing Manager</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>October 26, 2023 at 10:00 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              We’d like to celebrate our TMCF alum, <a href="https://www.linkedin.com/company/476234/admin/feed/posts/#" target="_blank" rel="noopener noreferrer">North Carolina Agricultural and Technical State University</a> alum and entrepreneur, <a href="https://www.linkedin.com/company/476234/admin/feed/posts/#" target="_blank" rel="noopener noreferrer">Albert Edney, II</a>! This ambitious changemaker was a three-time Leadership Institute attendee and he used our programming to excel in his future endeavors. He’s interested in a career in aerospace and space architecture. Albert is attending Johns Hopkins University and earning a Ph.D. in Civil Engineering. Let’s celebrate Albert and wish him well as he attends grad school.
              <br /> <br />
              If you’re a TMCF Scholar, share your story. We’d love to hear about your career journey. Sharing your story can inspire our next generation of leaders.
              <br /> <br />
              Share your story here: <a href="https://tm-cf.org/share-your-story" target="_blank" rel="noopener noreferrer">tm-cf.org/share-your-story</a>
              <br /> <br />
              <img
                width="100%"
                src="/images/img-albert-edney.png"
                alt="TMCF Alumni Spotlight Albert Edney"
              />
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Woman 4.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Precious Dorch-Robinson</NewsFeedCardName>
              <NewsFeedCardJob>Brand Marketing Manager</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>October 25, 2023 at 10:00 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              We're back!! Do you have any questions about our scholarships? Don't miss our Scholarship Team virtual office hours. If you're an HBCU student or a parent who wants to know more about applying for our scholarships, join our virtual office hour on TUESDAY, OCTOBER 10th! Our Scholarship Team has virtual office hours on Tuesdays from 10 a.m. ET - 4 p.m. ET through Tuesday, December 19.
              <br /> <br />
              See you tomorrow! <a href="https://tm-cf.org/OfficeHours" target="_blank" rel="noopener noreferrer">tm-cf.org/OfficeHours</a>
              <br /> <br />
              <img
                width="100%"
                src="/images/img-scholarship-office-hours.jpg"
                alt="TMCF Scholarship Team Virtual Office Hours"
              />
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Woman 4.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Precious Dorch-Robinson</NewsFeedCardName>
              <NewsFeedCardJob>Brand Marketing Manager</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>October 24, 2023 at 10:00 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Are you a college senior at risk of not graduating and in need of finances to complete your degree? Apply for the TMCF | Sallie Mae Fund’s Completing the Dream Gap Scholarship! Students completing a two-year, four-year or trade/vocational certificate/certification program are eligible to apply.
              <br /> <br />
              Don’t miss out on $2,500 in SCHOLARSHIPS! <a href="https://tm-cf.org/CTD23" target="_blank" rel="noopener noreferrer">Start your application today</a>.
              <br /> <br />
              <img
                width="100%"
                src="/images/img-salliemae.png"
                alt="TMCF Sallie Mae Scholarship"
              />
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Woman 4.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Precious Dorch-Robinson</NewsFeedCardName>
              <NewsFeedCardJob>Brand Marketing Manager</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>October 23, 2023 at 10:00 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Did you know we're on TikTok? Thurgood Marshall College Fund continues to be the go-to resource to support HBCU student success from college to career. You can find information on our scholarships, internships, professional development programs and learn career readiness tips on TikTok at <a href="https://tm-cf.org/TikTok" target="_blank" rel="noopener noreferrer">TMCFTheYard</a>.
              <br /> <br />
              Make sure you're following us to become competitive in the global job market and gain experiences that make you stand out.
              <br /> <br />
              <img
                width="100%"
                src="/images/img-tmcftheyard-tiktok.png"
                alt="TMCF TikTok"
              />
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              style={{
                width: 100,
                height: "auto",
              }}
              src={require("images/logo/tmcf-logo-black.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>TMCF Career Center Resources</NewsFeedCardName>
              <NewsFeedCardJob>Admin</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>February 9, 2023 at 1:52 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Let us serve you as your go-to student resource to make sure you
              reach your full potential as a TMCF scholar. Learn more about our
              professional development video series here:
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/tmcf-resource.mp4" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Woman 4.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Precious Dorch-Robinson</NewsFeedCardName>
              <NewsFeedCardJob>Brand Marketing Manager</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>February 9, 2023 at 11:48 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Ready to pursue your passion? <a target="_blank" href="https://www.linkedin.com/company/masterclassinc/">MasterClass</a> is giving students
              enrolled at HBCUs a free annual membership (legal terms apply).
              Access 180+ world-renowned instructors, hands-on tutorials, and
              thousands of bite-size lessons: Sign up here:
              <a target="_blank" href="https://tm-cf.org/masterclass">tm-cf.org/masterclass</a>.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <img
              style={{ width: "100%" }}
              src={require("images/tmcf/masterclass.png")}
            />
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Woman 4.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Precious Dorch-Robinson</NewsFeedCardName>
              <NewsFeedCardJob>Brand Marketing Manager</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>February 8, 2023 at 5:16 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Did you know February is one of the most popular hiring months? If
              you’re looking for a new job, make sure you’re prepared. Share
              these job interview tips.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <img
              style={{ width: "100%" }}
              src={require("images/tmcf/tmcf-questions.png")}
            />
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Woman 4.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Precious Dorch-Robinson</NewsFeedCardName>
              <NewsFeedCardJob>Brand Marketing Manager</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>February 7, 2023 at 2:26 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              There’s still time! Tickets for the #NBAHBCUClasssic 2023
              featuring Grambling State University and Southern University and
              A&M College are on sale! You don’t want to miss this match-up
              during #NBAAllStar ‘23. The NBA is bringing HBCU Pride to Salt
              Lake City. See you there! Tickets available:
              <a target="_blank" href="https://nbaevents.nba.com/allstar">nbaevents.nba.com/allstar</a>
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <img
              style={{ width: "100%" }}
              src={require("images/tmcf/nba-img.png")}
            />
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        {this.state.feed.map((feedItem) => {
          return feedItem.attachment === "" ? (
            <NewsFeedCard>
              <NewsFeedCardHeadingContainer>
                <Avatar
                  style={{
                    marginRight: 10,
                  }}
                  width={50}
                  height={50}
                  character={feedItem.user.avatar.slug}
                />
                <NewsFeedCardDetailsContainer>
                  <NewsFeedCardName>
                    {feedItem.user.firstName + " " + feedItem.user.lastName}
                  </NewsFeedCardName>
                  <NewsFeedCardJob>
                    {/* {feedItem.user.hasOwnProperty("recruiterProfile")
                      ? feedItem.user.recruiterProfile.jobTitle +
                        " at " +
                        feedItem.user.recruiterProfile.companyName
                      : "Professional Recruiter"} */}
                    {feedItem.user.hasOwnProperty("recruiterProfile") &&
                      "Hiring Partner"}
                  </NewsFeedCardJob>
                </NewsFeedCardDetailsContainer>
                <NewsFeedCardTime>
                  {moment(feedItem.createdAt).fromNow()}
                </NewsFeedCardTime>{" "}
              </NewsFeedCardHeadingContainer>
              <NewsFeedCardDescriptionContainer>
                <NewsFeedCardDescription>
                  {feedItem.message}
                </NewsFeedCardDescription>
              </NewsFeedCardDescriptionContainer>
            </NewsFeedCard>
          ) : (
            <NewsFeedCard>
              <NewsFeedCardHeadingContainer>
                <Avatar
                  style={{
                    marginRight: 10,
                  }}
                  width={50}
                  height={50}
                  character={feedItem.user.avatar.slug}
                />
                <NewsFeedCardDetailsContainer>
                  <NewsFeedCardName>
                    {" "}
                    {feedItem.user.firstName + " " + feedItem.user.lastName}
                  </NewsFeedCardName>
                  <NewsFeedCardJob>
                    {/* {feedItem.user.hasOwnProperty("recruiterProfile")
                      ? feedItem.user.recruiterProfile.jobTitle +
                        " at " +
                        feedItem.user.recruiterProfile.companyName
                      : "Professional Recruiter"} */}
                    {feedItem.user.hasOwnProperty("recruiterProfile") &&
                      "Hiring Partner"}
                  </NewsFeedCardJob>
                </NewsFeedCardDetailsContainer>
                <NewsFeedCardTime>
                  {moment(feedItem.createdAt).fromNow()}
                </NewsFeedCardTime>
              </NewsFeedCardHeadingContainer>
              <NewsFeedCardDescriptionContainer>
                <NewsFeedCardDescription>
                  {feedItem.message}
                </NewsFeedCardDescription>
              </NewsFeedCardDescriptionContainer>
              <NewsFeedVideoContainer>
                <img
                  style={{
                    width: "100%",
                  }}
                  src={feedItem.attachment}
                />
              </NewsFeedVideoContainer>
            </NewsFeedCard>
          );
        })}

        {/* 
        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Penguin.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Chris Hodges</NewsFeedCardName>
              <NewsFeedCardJob>CEO at Thurgood Marshall</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 19 at 4:18 PM</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Hey, this is Chris from Thurgood Marshall. I'm excited to have you join our
              platform to make the journey of finding the perfect job for you
              effortless, and simple. Thurgood Marshall fights for you both in terms of
              privacy, and the best job for you. We hope you enjoy using Thurgood Marshall.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard> */}

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage src={require("images/icons/Avatar/Tiger.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Thurgood Marshall
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 8 at 7:14 PM</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              You shouldn't have to be at a job that doesn't challenge you to
              move forward.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>

          <a
            style={{
              textDecoration: "none",
              color: "#000"
            }}
            href="https://www.gogig.com/blog/"
            target="_blank"
          >
            <NewsFeedBlogContainer>
              <NewsFeedBlogImg src="https://www.gogig.com/hubfs/Interview-questions-man.jpg" />
              <NewsFeedBlogMetaContainer>
                <NewsFeedBlogHeadline>
                  How 2020 Is Reshaping the Way We Find Jobs
                </NewsFeedBlogHeadline>
                <NewsFeedBlogDescription>
                  Whether there’s a lull in your particular job market or bias
                  within the workforce, there’s a long list of reasons you may
                  be having trouble finding your ideal position. Here are four
                  big factors that may be stopping you from landing the right
                  opportunity in 2020
                </NewsFeedBlogDescription>
              </NewsFeedBlogMetaContainer>
            </NewsFeedBlogContainer>
          </a>
        </NewsFeedCard> */}

        {/* <BrowserView>
          <NewsFeedCard>
            <BadgesContainer>
              <GoGigLogo src={require("images/logo/GoGig.jpg")} />
              <DownloadOnGoGig
                style={{
                  marginLeft: 30,
                  flexGrow: 1
                }}
              >
                Download Thurgood Marshall
              </DownloadOnGoGig>
              <div>
                <GetOnApple src="https://selinko.com/site/wp-content/uploads/2017/10/itunes-app-store-logo-300x104.png" />
                <GetOnGoogle src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
              </div>
            </BadgesContainer>
          </NewsFeedCard>
        </BrowserView> */}

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage src={require("images/icons/Avatar/Tiger.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Thurgood Marshall
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>March 27 at 2:26 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              You shouldn't have to be at a job that doesn't challenge you to
              move forward.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig.mp4#t=50" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard> */}

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Thurgood Marshall
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 1</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              You shouldn't have to be at a job that doesn't challenge you to
              move forward.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig.mp4#t=50" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Thurgood Marshall
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 2</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Don't worry about having to do all the hard work required with
              searching for your next position to move up. Use Thurgood Marshall effectively
              to make opportunities come to you.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig2.mp4#t=40" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Thurgood Marshall
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 3</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Because of the anonymous nature of Thurgood Marshall, don't worry about your
              employers finding you. Your identity is completely anonymous.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig3.mp4#t=30" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Penguin.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Thurgood Marshall
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 5</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Don't have any path to promotion? That's fine, Thurgood Marshall will help you
              get to the next level by bringing opportunities directly to you.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig5.mp4#t=10.0" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Thurgood Marshall
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 4</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Our customer support is available all the time. If you have any
              issues with your profile, send our team an email at
              support@gogig.com
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig4.mp4#t=20" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard> */}
      </div>
    );
  }
}

export default UserFeed;

const CompleteProfileCard = styled.div`
  background: white;
  padding: 20px;
  margin-top: 20px;
`;

const BadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GoGigLogo = styled.img`
  width: 60px;
`;
const DownloadOnGoGig = styled.p`
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  font-weight: bold;
`;
const GetOnApple = styled.img`
  height: 40px;
`;
const GetOnGoogle = styled.img`
  height: 60px;
`;

const NewsFeedBlogContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
  border: 1px solid #f5f5f5;
  align-items: center;
`;
const NewsFeedBlogImg = styled.img`
  height: 100px;
  width: 100px;
  margin-right: 10px;
  object-fit: cover;
`;
const NewsFeedBlogMetaContainer = styled.div``;
const NewsFeedBlogHeadline = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
`;
const NewsFeedBlogDescription = styled.p`
  font-size: 12px;
`;

const CompleteProfileCardTitleContainer = styled.div`
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
`;
const CompleteProfileCardTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  margin-left: 10px;
`;
const CompleteProfileCardDescription = styled.p`
  margin: 0;
  font-size: 14px;
  margin-bottom: 10px;
`;
const CompleteProfileCardButton = styled.button``;

const NewsFeedCard = styled.div`
  background: white;
  margin-top: 20px;
  padding: 20px;
`;
const NewsFeedCardHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 10px;
`;

const NewsFeedCardImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-right: 10px;
`;

const NewsFeedCardDetailsContainer = styled.div`
  flex: 3;
`;
const NewsFeedCardName = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  margin: 0;
`;
const NewsFeedCardJob = styled.p`
  font-size: 12px;
  color: #999;
  margin: 0;
`;
const NewsFeedCardTime = styled.p`
  font-size: 12px;
  color: #999;
  margin: 0;
`;
const NewsFeedCardDescriptionContainer = styled.div``;
const NewsFeedCardDescription = styled.p`
  color: #999;
  margin: 0;
  font-size: 14px;
  padding: 10px;
`;

const NewsFeedVideoContainer = styled.div`
  video {
    width: 100%;
  }
`;

const StyledSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f5f5f5;
  text-align: center;
  height: 100px;
  border-radius: 8px;
`;

const ProfilePhoto = styled.img`
  display: block;
  overflow: hidden;
  width: 100%;
  max-height: 500px;
`;
