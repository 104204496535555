import React, { Component } from "react";
import API from "API";
import {
  Modal,
  Form,
  Button,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { GeoSelectState, GeoSelectCity } from "GeoSelect";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Select from "react-select";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

class RecruiterOnboarding extends Component {
  state = {
    onboarding: {
      currentStep: 0,
    },
    employeeForecast: "",
    firstName: "",
    lastName: "",
    jobTitle: "",
    companyName: "",
    companyEmail: "",
    linkedIn: "",
    employmentType: {},
    employmentTypes: [],
    phoneNumber: "",
    recruiterEnabled: true,
    industries: [],
    jobPositions: [],
    jobPositionSpecialties: [],
    companyTypes: [],
    remoteOk: false,
    jobLevels: [],
    degrees: [],
    jobSearchStages: [],
    searchTitle: "",
    hiringCompany: "",
    jobPosition: {},
    jobPositionSpecialty: {},
    industry: {},
    degree: {},
    salaryMin: 0,
    salaryMax: 0,
    jobLevel: {},
    jobSearchStage: {},
    workExperienceMin: 0,
    workExperienceMax: 0,
    companyType: {},
    cities: [],
    newRecruiter: false,
    otherExplanation: ""
  };

  componentDidMount() {
    API.get(`/UserProfiles/${this.props.userToken.userId}`)
      .then((response) => {
        this.setState(response.data);
      })
      .then(() => {
        if (
          this.state.firstTimeRecruiterMode === true &&
          this.state.recruiterEnabled === true
        ) {
          this.setState({
            recruiterEnabled: false,
            onboarding: {
              currentStep: 2,
            },
          });
        }
      });
    API.get(`/Industries?filter={"order": "name ASC" }`).then((response) => {
      this.setState({
        industries: response.data,
      });
    });

    API.get(`/JobPositions?filter={"order": "name ASC" }`).then((response) => {
      this.setState({
        jobPositions: response.data,
      });
    });

    API.get(`/JobPositionSpecialties?filter={"order": "name ASC" }`).then(
      (response) => {
        this.setState({
          jobPositionSpecialties: response.data,
        });
      }
    );

    API.get(`/EmploymentTypes`).then((response) => {
      this.setState({
        employmentTypes: response.data,
      });
    });

    API.get(`/CompanyTypes?filter={"order": "priority ASC"}`).then(
      (response) => {
        this.setState({
          companyTypes: response.data,
        });
      }
    );

    API.get(`/JobLevels?filter={"order": "priority ASC"}`).then((response) => {
      this.setState({
        jobLevels: response.data,
      });
    });

    API.get(`/Degrees?filter={"order": "priority DESC"}`).then((response) => {
      this.setState({
        degrees: response.data,
      });
    });

    API.get(`/JobSearchStages`).then((response) => {
      this.setState({
        jobSearchStages: response.data,
      });
    });

    if (this.props.location.search.includes("true")) {
      this.setState({
        newRecruiter: true,
      });
    }
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    try {
      value = JSON.parse(value);
      this.setState({
        [name]: value,
      });
    } catch (e) {
      this.setState({
        [name]: value,
      });
    }
  };

  handleNext = () => {
    this.setState({
      onboarding: {
        currentStep: this.state.onboarding.currentStep + 1,
      },
    });
  };

  handleThankYou = () => {
    API.post(`/RecruiterProfiles`, {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      companyName: this.state.companyName,
      jobTitle: this.state.jobTitle,
      companyEmail: this.state.companyEmail,
      phoneNumber: this.state.phoneNumber,
      linkedIn: this.state.linkedIn,
      userProfileId: this.props.userToken.userId,
      otherExplanation: this.state.otherExplanation
    });
    this.setState({
      onboarding: {
        currentStep: 3,
      },
    });
  };

  handleSelectChange = (inputVals, opt) => {
    let { name } = opt;
    this.setState({
      [name]: [inputVals],
    });
  };

  handleSubmit = () => {
    API.post(`/RecruiterProfiles`, {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      companyName: this.state.companyName,
      jobTitle: this.state.jobTitle,
      companyEmail: this.state.companyEmail,
      phoneNumber: this.state.phoneNumber,
      linkedIn: this.state.linkedIn,
      userProfileId: this.props.userToken.userId,
    })
      .then((response) => {
        API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
          recruiterEnabled: true,
          recruiterProfileId: response.data.id,
          firstTimeRecruiterMode: false,
          newRecruiter: false,
        });
        API.post(`/RecruiterSearchQueries`, {
          searchTitle: this.state.searchTitle,
          hiringCompany: this.state.hiringCompany,
          remoteOk: this.state.remoteOk,
          jobPositionId: this.state.jobPosition.id,
          jobPositionSpecialtyId: this.state.jobPositionSpecialty.id,
          industryId: this.state.industry.id,
          degreeId: this.state.degree.id,
          salaryMin: this.state.salaryMin,
          salaryMax: this.state.salaryMax,
          jobLevelId: this.state.jobLevel.id,
          jobSearchStageId: this.state.jobSearchStage.id,
          workExperienceMin: this.state.workExperienceMin,
          workExperienceMax: this.state.workExperienceMax,
          companyTypeId: this.state.companyType.id,
          recruiterProfileId: response.data.id,
          userProfileId: this.props.userToken.userId,
          cities: this.state.cities,
          employmentTypeId: this.state.employmentType.id,
        });
        return Promise.resolve({});
      })
      .then((response) => {
        this.setState({
          recruiterEnabled: true,
          newRecruiter: false,
        });
      });
  };

  onSliderChange = (name, value) => {
    if (name === "salary") {
      this.setState({
        salaryMin: value[0],
        salaryMax: value[1],
      });
    }

    if (name === "experience") {
      this.setState({
        workExperienceMin: value[0],
        workExperienceMax: value[1],
      });
    }

    if (name == "searchDistance") {
      this.setState({
        searchDistanceMin: value,
      });
    }
  };

  render() {
    return (
      <Modal
        show={!this.state.recruiterEnabled || this.state.newRecruiter === true}
        size="lg"
        dialogClassName="modal-65w"
        centered
      >
        <Modal.Body>
          {
            {
              0: (
                <ModalContainer>
                  <img
                    style={{
                      position: "absolute",
                      zIndex: 100,
                      width: 175,
                      borderRadius: 8,
                      left: 30,
                      top: 30,
                    }}
                    src={require("images/logo/tmcf-logo-black.png")}
                  />
                  <ModalColumn className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
                    <img
                      style={{
                        height: "100%",
                        top: 85,
                        position: "relative",
                      }}
                      src={require("images/modals/blk-man-think.png")}
                    />
                  </ModalColumn>
                  <ModalColumn className="p-5 w-100 bg-white">
                    {this.state.newRecruiter ? (
                      <div>
                        <h1>Looking To Hire TMCF Members?</h1>
                        <p>
                          In the Hiring Mode of the platform, you will be able
                          to communicate with TMCF's team about your hiring
                          needs, which will give you access to their entire
                          network of alumni.
                        </p>
                      </div>
                    ) : (
                      <div>
                        <h1>Looking To Hire TMCF Members?</h1>
                        <p>
                          In the Hiring Mode of the platform, you will be able
                          to communicate with TMCF's team about your hiring
                          needs, which will give you access to their entire
                          network of alumni.
                        </p>
                      </div>
                    )}
                    <Form>
                      <Form.Row>
                        <Col>
                          <Form.Label>
                            Please select which best describes you from the
                            selection below:
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="employeeForecast"
                            onChange={this.handleChange}
                            value={this.state.employeeForecast}
                          >
                            <option value="" disabled>
                              Select an option
                            </option>
                            {/* <option value="tmcf_member">
                              I am a TMCF member that would like to hire other
                              HBCU alumni
                            </option> */}
                            <option value="company">
                              I am a company that would like to hire HBCU alumni
                            </option>
                            <option value="other">Other (please share)</option>
                          </Form.Control>
                        </Col>
                      </Form.Row>


                      {this.state.employeeForecast === "other" && (
                        <Form.Row>
                          <Col>
                            <Form.Control
                              className="mt-2"
                              style={{
                                backgroundColor: "#F5F9FB",
                              }}
                              name="otherExplanation"
                              onChange={this.handleChange}
                              as="textarea"
                              rows="5"
                              value={this.state.otherExplanation}
                              placeholder="Please explain..."
                            />
                          </Col>
                        </Form.Row>
                      )}

                      <Form.Row className="mt-4">
                        {!this.state.newRecruiter && (
                          <Link to="/">
                            <Button variant="outline-primary" className=" mr-2">
                              Cancel
                            </Button>
                          </Link>
                        )}
                        <Button className="ml-auto" onClick={this.handleNext}>
                          Next
                        </Button>
                      </Form.Row>
                    </Form>
                  </ModalColumn>
                </ModalContainer>
              ),
              1: (
                <ModalContainer>
                  <img
                    style={{
                      position: "absolute",
                      zIndex: 100,
                      width: 175,
                      borderRadius: 8,
                      left: 30,
                      top: 30,
                    }}
                    src={require("images/logo/tmcf-logo-black.png")}
                  />
                  <ModalColumn className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
                    <img
                      style={{
                        width: 300,
                        position: "relative",
                        top: 200,
                      }}
                      src={require("images/modals/blk-man-think.png")}
                    />
                  </ModalColumn>
                  <ModalColumn className="p-5 w-100 bg-white">
                    {this.state.employeeForecast === "<20" ? (
                      <div>
                        <h1>
                          Sign up for your free Thurgood Marshall training{" "}
                        </h1>
                        <p>
                          For your hiring needs, you get first 3 matches FREE
                          (no credit card required). To get started, fill out
                          the form below to schedule your training:
                        </p>
                      </div>
                    ) : (
                      <div>
                        <h1>
                          Thank you for signing up to speak with a Thurgood
                          Marshall College Fund Hiring Expert
                        </h1>
                        <p>
                          To speak with an expert on the TMCF team, please fill
                          out the below form and verify your email:
                        </p>
                      </div>
                    )}
                    <Form>
                      <Form.Row>
                        <Col>
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type first name here"
                            name="firstName"
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col>
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type last name here"
                            name="lastName"
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row className="mt-4">
                        <Col>
                          <Form.Label>Company Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type your company name here"
                            name="companyName"
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col>
                          <Form.Label>Your job title</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type your job title here"
                            name="jobTitle"
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row className="mt-4">
                        <Col>
                          <Form.Label>Company e-mail</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Type your company e-mail here"
                            name="companyEmail"
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col>
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type your phone number here"
                            name="phoneNumber"
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row className="mt-4">
                        <Col>
                          <Form.Label>LinkedIn Profile Link</Form.Label>
                          <Form.Control
                            type="textarea"
                            placeholder="Enter your LinkedIn Profile"
                            name="linkedIn"
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row className="mt-4">
                        {!this.state.newRecruiter && (
                          <Link to="/">
                            <Button variant="outline-primary" className="mr-2">
                              Cancel
                            </Button>
                          </Link>
                        )}
                        {this.state.recruiterEnabled ? (
                          <Button className="ml-auto" onClick={this.handleNext}>
                            Next
                          </Button>
                        ) : (
                          <Button
                            className="ml-auto"
                            onClick={this.handleThankYou}
                          >
                            Finish
                          </Button>
                        )}
                      </Form.Row>
                    </Form>
                  </ModalColumn>
                </ModalContainer>
              ),
              2: (
                <ModalContainer>
                  <img
                    style={{
                      position: "absolute",
                      zIndex: 100,
                      width: 175,
                      borderRadius: 8,
                      left: 30,
                      top: 30,
                    }}
                    src={require("images/logo/tmcf-logo-black.png")}
                  />
                  <ModalColumn>
                    <img
                      style={{
                        width: 400,
                      }}
                      src={require("images/modals/gogig-career-desires.png")}
                    />
                  </ModalColumn>
                  <ModalColumn className="p-5 w-100 bg-white">
                    <h1>Build a New TMCF HBCU Alumni Career Connect Search</h1>
                    <p className="text-muted">
                      You can build as many searches as you want after you build
                      this one.
                    </p>
                    <Form.Row>
                      <Col>
                        <Form.Label>Search Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="searchTitle"
                          onChange={this.handleChange}
                          placeholder="Search Title"
                        />
                      </Col>
                      <Col>
                        <Form.Label>Hiring Company</Form.Label>
                        <Form.Control
                          type="text"
                          name="hiringCompany"
                          onChange={this.handleChange}
                          placeholder="Company you're hiring on behalf of"
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>
                          What type of position are you hiring for?
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={this.handleChange}
                          name="jobPosition"
                          value={JSON.stringify(this.state.jobPosition)}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Search Position Type
                          </option>
                          {this.state.jobPositions.map((jobPosition) => (
                            <option value={JSON.stringify(jobPosition)}>
                              {jobPosition.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    {Object.keys(this.state.jobPosition).length !== 0 && (
                      <Form.Row className="mt-4">
                        <Col>
                          <Form.Label>
                            What specialty within {this.state.jobPosition.name}{" "}
                            are you hiring for?
                          </Form.Label>
                          <Form.Control
                            as="select"
                            onChange={this.handleChange}
                            name="jobPositionSpecialty"
                            value={JSON.stringify(
                              this.state.jobPositionSpecialty
                            )}
                          >
                            <option disabled value={JSON.stringify({})}>
                              Select A Position Specialty
                            </option>
                            {this.state.jobPositionSpecialties.map(
                              (jobPositionSpecialty) => {
                                if (
                                  jobPositionSpecialty.jobPositionId ===
                                  this.state.jobPosition.id
                                ) {
                                  return (
                                    <option
                                      value={JSON.stringify(
                                        jobPositionSpecialty
                                      )}
                                    >
                                      {jobPositionSpecialty.name}
                                    </option>
                                  );
                                }
                              }
                            )}
                          </Form.Control>
                        </Col>
                      </Form.Row>
                    )}
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>Hiring Company Industry</Form.Label>
                        <Form.Control
                          as="select"
                          name="industry"
                          value={JSON.stringify(this.state.industry)}
                          onChange={this.handleChange}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Choose a field
                          </option>
                          {this.state.industries.map((industry) => (
                            <option value={JSON.stringify(industry)}>
                              {industry.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Label>Minimum Degree Level Required</Form.Label>
                        <Form.Control
                          as="select"
                          name="degree"
                          value={JSON.stringify(this.state.degree)}
                          onChange={this.handleChange}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Choose a field
                          </option>
                          {this.state.degrees.map((degree) => (
                            <option value={JSON.stringify(degree)}>
                              {degree.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-5">
                      <Col>
                        <Form.Row>
                          <Form.Label>Minimum/Maximum Salary</Form.Label>
                          <SliderValueContainer>
                            <SliderValue>
                              ${this.state.salaryMin} - ${this.state.salaryMax}
                            </SliderValue>
                          </SliderValueContainer>
                        </Form.Row>
                        <Range
                          min={0}
                          max={500000}
                          onChange={(d) => this.onSliderChange("salary", d)}
                          marks={{
                            0: "$0",
                            500000: "$500,000+",
                          }}
                          step={10000}
                          defaultValue={[
                            this.state.salaryMin,
                            this.state.salaryMax,
                          ]}
                          tipFormatter={(value) => `${value}%`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10,
                          }}
                          trackStyle={[
                            { backgroundColor: "#D3202A", height: 10 },
                            { backgroundColor: "#D3202A", height: 10 },
                          ]}
                          handleStyle={[
                            {
                              backgroundColor: "#D3202A",
                              borderColor: "#D3202A",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                            {
                              backgroundColor: "#D3202A",
                              borderColor: "#D3202A",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                          ]}
                          dotStyle={{
                            display: "none",
                          }}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-5">
                      <Col>
                        <Form.Label>Job Level</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={this.handleChange}
                          name="jobLevel"
                          value={JSON.stringify(this.state.jobLevel)}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Choose a field
                          </option>
                          {this.state.jobLevels.map((jobLevel) => (
                            <option value={JSON.stringify(jobLevel)}>
                              {jobLevel.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Label>Job Type</Form.Label>
                        <Form.Control
                          as="select"
                          name="employmentType"
                          onChange={this.handleChange}
                          value={JSON.stringify(this.state.employmentType)}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Choose a field
                          </option>
                          {this.state.employmentTypes.map((employmentType) => (
                            <option value={JSON.stringify(employmentType)}>
                              {employmentType.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>Company Type</Form.Label>
                        <Form.Control
                          as="select"
                          name="companyType"
                          value={JSON.stringify(this.state.companyType)}
                          onChange={this.handleChange}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Choose a field
                          </option>
                          {this.state.companyTypes.map((companyType) => (
                            <option value={JSON.stringify(companyType)}>
                              {companyType.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col className="text-center align-self-center">
                        <Form.Label></Form.Label>
                        <Form.Group controlId="remoteOk">
                          <Form.Check
                            name="remoteOk"
                            onChange={() => {
                              this.setState({
                                remoteOk: !this.state.remoteOk,
                              });
                            }}
                            type="checkbox"
                            checked={this.state.remoteOk}
                            label="Position allows work from remote"
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Row>
                          <Form.Label>Total Years Work Experience</Form.Label>
                          <SliderValueContainer>
                            <SliderValue>
                              {this.state.workExperienceMin} years -{" "}
                              {this.state.workExperienceMax} years
                            </SliderValue>
                          </SliderValueContainer>
                        </Form.Row>
                        <Range
                          defaultValue={[
                            this.state.workExperienceMin,
                            this.state.workExperienceMax,
                          ]}
                          min={0}
                          max={25}
                          onChange={(d) => this.onSliderChange("experience", d)}
                          marks={{
                            0: "0",
                            25: "25+ Yrs",
                          }}
                          tipFormatter={(value) => `${value}%`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10,
                          }}
                          trackStyle={[
                            { backgroundColor: "#D3202A", height: 10 },
                            { backgroundColor: "#D3202A", height: 10 },
                          ]}
                          handleStyle={[
                            {
                              backgroundColor: "#D3202A",
                              borderColor: "#D3202A",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                            {
                              backgroundColor: "#D3202A",
                              borderColor: "#D3202A",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                          ]}
                          dotStyle={{
                            display: "none",
                          }}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>Job Location</Form.Label>
                        <GeoSelectCity
                          isMulti={false}
                          name="cities"
                          handleChange={this.handleSelectChange}
                          value={this.state.cities}
                          placeholder="Enter City and State"
                        />
                      </Col>
                    </Form.Row>

                    {this.state.cities.length !== 0 && (
                      <Form.Row className="mt-5">
                        <Col>
                          <Form.Row>
                            <Form.Label>
                              Mile Radius Around {this.state.cities[0].label}
                            </Form.Label>
                            <SliderValueContainer>
                              <SliderValue>
                                {this.state.searchDistanceMin}mi
                              </SliderValue>
                            </SliderValueContainer>
                          </Form.Row>
                          <Slider
                            min={0}
                            max={400}
                            onChange={(d) =>
                              this.onSliderChange("searchDistance", d)
                            }
                            marks={{
                              0: "0mi",
                              400: "400mi",
                            }}
                            defaultValue={this.state.searchDistanceMin}
                            tipFormatter={(value) => `${value}%`}
                            railStyle={{
                              backgroundColor: "#f5f5f5",
                              height: 10,
                            }}
                            trackStyle={[
                              { backgroundColor: "#D3202A", height: 10 },
                              { backgroundColor: "#D3202A", height: 10 },
                            ]}
                            handleStyle={[
                              {
                                backgroundColor: "#D3202A",
                                borderColor: "#D3202A",
                                width: 17,
                                height: 17,
                                top: 6,
                              },
                              {
                                backgroundColor: "#D3202A",
                                borderColor: "#D3202A",
                                width: 17,
                                height: 17,
                                top: 6,
                              },
                            ]}
                            dotStyle={{
                              display: "none",
                            }}
                          />
                        </Col>
                      </Form.Row>
                    )}

                    <Form.Row className="mt-5">
                      {/* <Button
                        variant="outline-primary"
                        className="  mr-2"
                        onClick={() => this.props.handleModalShow("")}
                      >
                        Cancel
                      </Button> */}

                      <Button className="ml-auto" onClick={this.handleSubmit}>
                        Create Search Query
                      </Button>
                    </Form.Row>
                  </ModalColumn>
                </ModalContainer>
              ),
              3: (
                <ModalContainer>
                  <img
                    style={{
                      position: "absolute",
                      zIndex: 100,
                      width: 175,
                      borderRadius: 8,
                      left: 30,
                      top: 30,
                    }}
                    src={require("images/logo/tmcf-logo-black.png")}
                  />
                  <ModalColumn className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
                    <img
                      style={{
                        width: 400,
                        bottom: 0,
                      }}
                      src={require("images/modals/blk-man-think.png")}
                    />
                  </ModalColumn>
                  <ModalColumn className="p-5 w-100 bg-white">
                    {this.state.employeeForecast === "<20" ? (
                      <div>
                        <h1>
                          Thank you for your interest in Thurgood Marshall
                        </h1>
                        <p>
                          Someone from the Thurgood Marshall Team will reach out
                          to you within 24 hours to schedule your training to
                          try Thurgood Marshall for FREE.
                        </p>
                        <p>
                          If you don't hear from anyone in that time frame,{" "}
                          <b>feel free to email us at sales@gogig.com </b>
                          and we'll reply.
                        </p>
                      </div>
                    ) : (
                      <div>
                        <h1>
                          Thank you for signing up to speak with a Thurgood
                          Marshall College Fund Hiring Expert
                        </h1>
                        <p>
                          Someone from the TMCF Team will reach out to you soon
                          to discuss your hiring needs.
                        </p>
                      </div>
                    )}
                    <Form.Row className="mt-4">
                      <a href="https://www.tmcf.org/students-alumni/tmcf-alumni/ ">
                        <Button className="ml-auto">Exit</Button>
                      </a>
                    </Form.Row>
                  </ModalColumn>
                </ModalContainer>
              ),
              4: <div />,
              5: (
                <Container className="p-5">
                  <img
                    style={{
                      position: "absolute",
                      zIndex: 100,
                      width: 175,
                      borderRadius: 8,
                      left: 30,
                      top: 30,
                    }}
                    src={require("images/logo/tmcf-logo-black.png")}
                  />
                  <ModalContainer>
                    <img
                      className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block"
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        bottom: 0,
                      }}
                      src={require("images/modals/gogig-onboarding-2.png")}
                    />
                    <div
                      className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block"
                      style={{
                        height: 450,
                      }}
                    />
                    <h1>Passive Job Seeker</h1>
                    <p
                      style={{
                        maxWidth: "550px",
                      }}
                    >
                      Whether you have a job or not, Thurgood Marshall is built
                      for you! Don't expect to see any job postings here. Once
                      your profile is complete, keep your notification settings
                      on so you can respond to any company matches you get.
                      Otherwise, Thurgood Marshall stays quiet.
                    </p>
                  </ModalContainer>

                  <Row className="float-right">
                    <Col xs={12}>
                      <Button className=" " onClick={this.incrementStep}>
                        Next
                      </Button>
                    </Col>
                  </Row>
                </Container>
              ),
              6: (
                <ModalContainer>
                  <ModalColumn>
                    <img
                      style={{
                        width: 400,
                      }}
                      src={require("images/modals/man-woman-vertical.png")}
                    />
                  </ModalColumn>
                  <ModalColumn className="p-5 w-100 bg-white">
                    <h1>Build a New TMCF HBCU Alumni Career Connect Search</h1>
                    <p>
                      You can build as many searches as you want after you build
                      this one.
                    </p>
                    <Form.Row>
                      <Col>
                        <Form.Label>Search Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="searchTitle"
                          onChange={this.handleChange}
                          placeholder="Search Title"
                        />
                      </Col>
                      <Col>
                        <Form.Label>Hiring Company</Form.Label>
                        <Form.Control
                          type="text"
                          name="hiringCompany"
                          onChange={this.handleChange}
                          placeholder="Company you're hiring for"
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>
                          What type of position are you hiring for?
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={this.handleChange}
                          name="jobPosition"
                          value={JSON.stringify(this.state.jobPosition)}
                        >
                          <option disabled value={JSON.stringify({})}>
                            -- select an option --
                          </option>
                          {this.state.jobPositions.map((jobPosition) => (
                            <option value={JSON.stringify(jobPosition)}>
                              {jobPosition.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>Position Specialty</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={this.handleChange}
                          name="jobPositionSpecialty"
                          value={JSON.stringify(
                            this.state.jobPositionSpecialty
                          )}
                        >
                          <option disabled value={JSON.stringify({})}>
                            -- select an option --
                          </option>
                          {this.state.jobPositionSpecialties.map(
                            (jobPositionSpecialty) => {
                              if (
                                jobPositionSpecialty.jobPositionId ===
                                this.state.jobPosition.id
                              ) {
                                return (
                                  <option
                                    value={JSON.stringify(jobPositionSpecialty)}
                                  >
                                    {jobPositionSpecialty.name}
                                  </option>
                                );
                              }
                            }
                          )}
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>Minimum Degree Level Required</Form.Label>
                        <Form.Control
                          as="select"
                          name="degree"
                          value={JSON.stringify(this.state.degree)}
                          onChange={this.handleChange}
                        >
                          <option disabled value={JSON.stringify({})}>
                            -- select an option --
                          </option>
                          {this.state.degrees.map((degree) => (
                            <option value={JSON.stringify(degree)}>
                              {degree.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>Job Location</Form.Label>
                        <GeoSelectCity
                          isMulti={false}
                          name="cities"
                          handleChange={this.handleSelectChange}
                          value={this.state.cities}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-5">
                      <Col>
                        <Form.Row>
                          <Form.Label>Search Distance</Form.Label>
                          <SliderValueContainer>
                            <SliderValue>
                              {this.state.searchDistanceMin}mi
                            </SliderValue>
                          </SliderValueContainer>
                        </Form.Row>
                        <Slider
                          min={0}
                          max={400}
                          onChange={(d) =>
                            this.onSliderChange("searchDistance", d)
                          }
                          marks={{
                            0: "0mi",

                            400: "400mi",
                          }}
                          defaultValue={this.state.searchDistanceMin}
                          tipFormatter={(value) => `${value}%`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10,
                          }}
                          trackStyle={[
                            { backgroundColor: "#D3202A", height: 10 },
                            { backgroundColor: "#D3202A", height: 10 },
                          ]}
                          handleStyle={[
                            {
                              backgroundColor: "#D3202A",
                              borderColor: "#D3202A",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                            {
                              backgroundColor: "#D3202A",
                              borderColor: "#D3202A",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                          ]}
                          dotStyle={{
                            display: "none",
                          }}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-5">
                      <Col>
                        <Form.Row>
                          <Form.Label>Salary Range</Form.Label>
                          <SliderValueContainer>
                            <SliderValue>
                              ${this.state.salaryMin} - ${this.state.salaryMax}
                            </SliderValue>
                          </SliderValueContainer>
                        </Form.Row>
                        <Range
                          min={0}
                          max={500000}
                          onChange={(d) => this.onSliderChange("salary", d)}
                          marks={{
                            0: "$0",
                            500000: "$500,000+",
                          }}
                          defaultValue={[
                            this.state.salaryMin,
                            this.state.salaryMax,
                          ]}
                          tipFormatter={(value) => `${value}%`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10,
                          }}
                          trackStyle={[
                            { backgroundColor: "#D3202A", height: 10 },
                            { backgroundColor: "#D3202A", height: 10 },
                          ]}
                          handleStyle={[
                            {
                              backgroundColor: "#D3202A",
                              borderColor: "#D3202A",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                            {
                              backgroundColor: "#D3202A",
                              borderColor: "#D3202A",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                          ]}
                          dotStyle={{
                            display: "none",
                          }}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-5">
                      <Col>
                        <Form.Label>Job Level</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={this.handleChange}
                          name="jobLevel"
                          value={JSON.stringify(this.state.jobLevel)}
                        >
                          <option disabled value={JSON.stringify({})}>
                            -- select an option --
                          </option>
                          {this.state.jobLevels.map((jobLevel) => (
                            <option value={JSON.stringify(jobLevel)}>
                              {jobLevel.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Label>Job Type</Form.Label>
                        <Form.Control
                          as="select"
                          name="employmentType"
                          onChange={this.handleChange}
                          value={JSON.stringify(this.state.employmentType)}
                        >
                          <option disabled value={JSON.stringify({})}>
                            -- select an option --
                          </option>
                          {this.state.employmentTypes.map((employmentType) => (
                            <option value={JSON.stringify(employmentType)}>
                              {employmentType.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>Company Type</Form.Label>
                        <Form.Control
                          as="select"
                          name="companyType"
                          value={JSON.stringify(this.state.companyType)}
                          onChange={this.handleChange}
                        >
                          <option disabled value={JSON.stringify({})}>
                            -- select an option --
                          </option>
                          {this.state.companyTypes.map((companyType) => (
                            <option value={JSON.stringify(companyType)}>
                              {companyType.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col className="text-center align-self-center">
                        <Form.Label></Form.Label>
                        <Form.Group controlId="remoteOk">
                          <Form.Check
                            name="remoteOk"
                            onChange={() => {
                              this.setState({
                                remoteOk: !this.state.remoteOk,
                              });
                            }}
                            type="checkbox"
                            checked={this.state.remoteOk}
                            label="Position allows work from remote"
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Row>
                          <Form.Label>Total Years Work Experience</Form.Label>
                          <SliderValueContainer>
                            <SliderValue>
                              {this.state.workExperienceMin} years -{" "}
                              {this.state.workExperienceMax} years
                            </SliderValue>
                          </SliderValueContainer>
                        </Form.Row>
                        <Range
                          defaultValue={[
                            this.state.workExperienceMin,
                            this.workExperienceMax,
                          ]}
                          min={0}
                          max={25}
                          onChange={(d) => this.onSliderChange("experience", d)}
                          marks={{
                            0: "0",
                            25: "25+ Yrs",
                          }}
                          tipFormatter={(value) => `${value}%`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10,
                          }}
                          trackStyle={[
                            { backgroundColor: "#D3202A", height: 10 },
                            { backgroundColor: "#D3202A", height: 10 },
                          ]}
                          handleStyle={[
                            {
                              backgroundColor: "#D3202A",
                              borderColor: "#D3202A",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                            {
                              backgroundColor: "#D3202A",
                              borderColor: "#D3202A",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                          ]}
                          dotStyle={{
                            display: "none",
                          }}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4 pt-4">
                      {!this.state.newRecruiter && (
                        <Link to="/">
                          <Button variant="outline-primary" className="  mr-2">
                            Cancel
                          </Button>
                        </Link>
                      )}
                      <Button className="ml-auto" onClick={this.handleSubmit}>
                        Submit
                      </Button>
                    </Form.Row>
                  </ModalColumn>
                </ModalContainer>
              ),
            }[this.state.onboarding.currentStep]
          }
        </Modal.Body>
      </Modal>
    );
  }
}

export default RecruiterOnboarding;

const BackgroundImg = styled.div`
  width: 300px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://farm4.staticflickr.com/3902/14985871946_24f47d4b53_h.jpg");
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  margin: -16px;
`;
const ModalColumn = styled.div`
  background: #edeff0;
`;

const SliderValueContainer = styled.p`
  margin: 0;
  padding: 0;
  margin-left: auto;
  font-weight: 500;
  color: #020000;
`;
const SliderValue = styled.p`
  margin: 0;
  padding: 0;
`;

const ModalContainerTwo = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalCol = styled.div`
  background-color: #edeff0;
`;
