import React from "react";
import styled from "styled-components";

export default {
  title: "Links",
};

export const PrimaryLink = styled.p`
  font-family: Roboto;
  color: #ED1C23;
  font-size: ${(props) => (props.size ? props.size : "")};
`;

export const StoryPrimaryLink = () => <PrimaryLink>Link</PrimaryLink>;
